/* eslint-disable camelcase */
import React, { useState } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { Collapse } from '@mui/material';
import {
  ArrowDown,
  ArrowRight,
  CentralizedBox,
  NoReservationText,
  StaysText
} from './ReservationList.styles';
import { t } from '../../../js/common/translations';
import ReservationCard from './ReservationCard';
import { Box, Button, Dialog, Drawer, SvgIcon } from '../elements';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useToggle } from '../../hooks/useToggle';
import { DialogGuestSubtitle, DialogGuestTitle, ModalContainer } from './PropertyCard.styles';

const ReservationList = ({
  confirmed,
  previous,
  pending,
  links,
  pendingInvitations = []
}) => {
  const [openPrevious, setOpenPrevious] = useState(false);
  const [openPending, setOpenPending] = useState(true);
  const mobile = ['xs', 'sm'].includes(useBreakpoint());
  const [openPendingInvitations, setOpenPendingInvitations] = useToggle(pendingInvitations.length > 0);

  if (confirmed.length === 1 && previous.length === 0 && pending.length === 0 && pendingInvitations.length === 0) {
    window.location.href = confirmed[0].path;
  }

  const visitInvitesPage = async() => {
    window.location.href = '/member/invites';
    setOpenPendingInvitations();
  };

  const DrawerOrDialog = () => {
    return mobile ? (
      <Drawer
        anchor="bottom"
        open={openPendingInvitations}
        onClose={setOpenPendingInvitations}
      >
        <ModalContainer>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <SvgIcon name="blue_info" size="72" mb={1} />
            <DialogGuestSubtitle pb={2.5}>
              {t('member_reservation.reservation_invite.dialog_subtitle_invited_to_many')}
            </DialogGuestSubtitle>
            <Box
              px={{ xs: 1, md: 0 }}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Button
                onClick={() => setOpenPendingInvitations()}
                variant="text"
                mr={0.25}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.close_invitations_btn')}
              </Button>
              <Button
                onClick={() => visitInvitesPage()}
                ml={0.25}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.confirm_invitations_btn')}
              </Button>
            </Box>
          </Box>
        </ModalContainer>
      </Drawer>
    ) : (
      <Dialog
        titleAlign='flex-start'
        open={openPendingInvitations}
        className={''}
        onClose={setOpenPendingInvitations}
        sx={{
          width: 1,
          '& .MuiDialog-paper': { backgroundColor: pendingInvitations[0]?.ownership === 'owner' ? 'background.100' : 'secondary.main' },
          '& .MuiDialogContent-root': { backgroundColor: 'secondary.main' }
        }}
      >
        <ModalContainer>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <SvgIcon name="blue_info" size="72" mb={1} />
            <DialogGuestTitle>{t('member_reservation.reservation_invite.dialog_title_guest')}</DialogGuestTitle>
            <DialogGuestSubtitle pb={2.5}>
              {t('member_reservation.reservation_invite.dialog_subtitle_invited_to_many')}
            </DialogGuestSubtitle>
            <Box px={{ xs: 1, md: 0 }} display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
              <Button
                onClick={() => setOpenPendingInvitations()}
                variant="text"
                mr={0.25}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.close_invitations_btn')}
              </Button>
              <Button
                onClick={() => visitInvitesPage()}
                ml={0.25}
                sx={{
                  width: { xs: '100%', md: 'auto' }
                }}
              >
                {t('member_reservation.reservation_invite.confirm_invitations_btn')}
              </Button>
            </Box>
          </Box>
        </ModalContainer>
      </Dialog>
    );
  };

  return (
    <ThemeProvider>
      {confirmed.length === 0 && previous.length === 0 && pending.length === 0 ? (
        <CentralizedBox>
          <SvgIcon name="empty_reservations" />
          <NoReservationText>{t('bookings_page.no_reservations')}</NoReservationText>
          <Button
            size="large"
            onClick={() => (window.location.href = links.apartments_path)}
            sx={{
              width: '80vw',
              maxWidth: '400px'
            }}
          >
            {t('bookings_page.find_btn')}
          </Button>
        </CentralizedBox>
      ) : (
        <Box width="100%" maxWidth="1000px" padding="0 1rem 3rem 1rem">
          <Box>
            {confirmed.map((property) => (
              <Box key={property.reservation_id} paddingBottom="0.5rem">
                <ReservationCard property={property} mobile={mobile}/>
              </Box>
            ))}
          </Box>
          {!!pending.length && (
            <>
              <StaysText
                onClick={() => {
                  setOpenPending((openPending) => !openPending);
                }}
              >
                {t('bookings_page.pending')}
                {openPending ? <ArrowDown /> : <ArrowRight />}
              </StaysText>
              <Collapse in={openPending} timeout="auto" unmountOnExit>
                {pending.map((property) => (
                  <Box key={property.reservation_id} paddingBottom="0.5rem" display="flex">
                    <ReservationCard property={property} disabled={true} mobile={mobile}/>
                  </Box>
                ))}
              </Collapse>
            </>
          )}
          {!!previous.length && (
            <>
              <StaysText
                onClick={() => {
                  setOpenPrevious((openPrevious) => !openPrevious);
                }}
              >
                {t('bookings_page.previous')}
                {openPrevious ? <ArrowDown /> : <ArrowRight />}
              </StaysText>
              <Collapse in={openPrevious} timeout="auto" unmountOnExit>
                {previous.map((property) => (
                  <Box key={property.reservation_id} paddingBottom="0.5rem">
                    <ReservationCard property={property} disabled={true} mobile={mobile}/>
                  </Box>
                ))}
              </Collapse>
            </>
          )}
        </Box>
      )}
      {DrawerOrDialog()}
    </ThemeProvider>
  );
};

export default ReservationList;
