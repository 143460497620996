import styled, { css } from 'styled-components';
import { Paper as MuiPaper } from '@mui/material';

const Paper = styled(MuiPaper)`
  ${({ elevation }) => (elevation === undefined || elevation === 1) && css`
    && {
      box-shadow: 0px 0.5px 2px rgba(41, 45, 50, 0.16);
    }
  `};
`;

export default Paper;
