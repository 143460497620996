import React from 'react';
import styled, { css } from 'styled-components';
import {
  Container,
  Button,
  Box,
  SvgIcon,
  Typography,
  Link,
  ListItem,
  IconButton
} from '../elements';

export const ToolbarContainer = styled(Container).attrs({
  maxWidth: 'xl'
})`
  && {
    display: flex;
    padding: 0;
  }
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

export const LinkIcon = styled(Box).attrs({
  component: 'a'
})`
  height: 100%;
  display: flex;
  align-self: center;
  ${({
    theme,
    searchNeighborhoodVisible
  }) => searchNeighborhoodVisible && css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `}
`;

export const TabasLogo = styled(SvgIcon)`
  min-width: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  max-height: 30px;
  padding-right: 0.5rem;
`;

export const SearchNeighborhoodContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitleContainer = styled(Box)`
  flex: 1;
  padding: 0px 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const LinksContainer = styled(Box)`
  width: 100%;
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  margin-bottom: 0;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const TextButton = styled(Button).attrs({
  variant: 'text',
  pill: false
})``;

export const HeaderLink = styled(Link).attrs({
  variant: 'overline',
  component: TextButton
})`
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  list-style-type: none;
  font-weight: 400;
  padding: 0 15px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 0 8px;
  }
  ${({ $active }) => $active && css`
    && {
      font-weight: 500;
    }
  `}
`;

export const HeaderActionsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: fit-content;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: ${({ casatb }) => casatb ? 'flex' : 'none'};
  }
`;

export const SupportMenuItem = styled(ListItem).attrs({
  component: Link,
  target: '_blank',
  button: true
})`
  display: flex;
  flex-direction: row;
`;

export const SupportMenuItemLabel = styled(Typography)`
  width: 100%;
  padding: 8px 16px 4px 16px;
  text-transform: capitalize;
  font-size: 14px;
`;

export const SupportMenuItemDescription = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const HeaderItemIcon = styled(SvgIcon)`
  margin-right: 8px;
`;

export const MemberMenuButton = styled(HeaderLink)`
  white-space: nowrap;
`;

export const HamburguerIcon = styled(SvgIcon).attrs({
  name: 'hamburguer_menu',
  size: 20
})``;

export const HamburguerBtn = styled(IconButton).attrs({
  children: <HamburguerIcon />
})`
  width: 40px;
  height: 40px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const CityButtonText = styled.span`
  color: ${({ theme, color }) => color === 'secondary' ? theme.palette.secondary['70'] : theme.palette.primary['70']};
`;
