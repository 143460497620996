import styled from 'styled-components';
import {
  Divider,
  ListItemIcon as MuiListItemIcon,
  Typography
} from '../elements';

export const Greetings = styled(Typography).attrs({
  fontSize: '0.75rem',
  paddingBottom: '0.5rem'
})`
  opacity: 0.7;
`;

export const UserName = styled(Typography).attrs({
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.5rem'
})``;

export const ListItemIcon = styled(MuiListItemIcon)`
  padding-right: 0.5rem;
  min-width: 0;
`;

export const DividerStyled = styled(Divider)`
  margin-left: 20%;
  opacity: 0.1;
  border-color: ${({ theme }) => theme.palette.primary.main};
`;
