import React, { useMemo } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { LinearProgress } from '@mui/material';
import { Typography, Box } from '.';
import SvgIcon from './SvgIcon';
import { t } from '../../../js/common/translations';

const passwordVariations = [['error', 'weakly'], ['error', 'weakest'], ['error', 'weak'], ['warning', 'strong'], ['success', 'strongest']];

const PasswordStrength = (length) => {
  const [color, description] = useMemo(() => passwordVariations[length.length], [length]);

  return (
    <ThemeProvider>
      <LinearProgress variant="determinate" value={25 * length.length} color={color} sx={{ borderRadius: 6 }} />
      <Box display="flex" mt={0.25}>
        <SvgIcon name="info" mr={0.25} /><Typography color='primary.main' fontSize={10}>{t(`signup_page.password_${description}`)}</Typography>
      </Box>
    </ThemeProvider>);
};

export default PasswordStrength;
