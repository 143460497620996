import React, { useState } from 'react';
import { Box, Link } from '../elements';
import {
  DescriptionTitle,
  DescriptionText
} from './ApartmentDescription.styles';
import { t } from '../../../js/common/translations';

const ApartmentDescription = ({
  apartmentSummary,
  translationVariant,
  casatb
}) => {
  const [showDescription, setShowDescription] = useState(true);

  return (
    <Box>
      <Box sx={{ fontFamily: 'Poppins', fontSize: '1rem' }}>
        {apartmentSummary}
      </Box>
      <DescriptionTitle casatb={casatb}>
        {t('furnished_apartment_title')}
      </DescriptionTitle>
      <DescriptionText marginBottom="0.5rem" casatb={casatb}>
        {t('furnished_apartment_description')}
      </DescriptionText>
      <Link onClick={() => setShowDescription(!showDescription)} fontSize="0.85rem">{showDescription ? t('more_details') : t('less_details')}</Link>
      <Box hidden={showDescription}>
        {[['first_title', 'first'], ['second_title', 'second'], ['third_title', 'third'], ['fourth_title', 'fourth']].map(([title, text], index) => (
          <Box key={index}>
            <DescriptionTitle casatb={casatb}>
              {t(`${translationVariant}.${title}`)}
            </DescriptionTitle>
            <DescriptionText casatb={casatb}>
              {t(`${translationVariant}.${text}`)}
            </DescriptionText>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ApartmentDescription;
