import React, { useEffect, useState } from 'react';
import { Grid, Box, LinearProgress, Button, Typography } from '../elements';
import PropertyCard from './PropertyCard';
import { ThemeProvider } from '../../contexts/theme';
import eventBus from '../../../js/common/eventBus';
import { t } from '../../../js/common/translations';
import axios from 'axios';

const PropertyList = ({
  properties = [],
  propertiesLength = 0,
  currentMemberIsAdmin = false,
  colsPerBreakpoint = {},
  showPagination = true,
  gridSpacing,
  url,
  casatb,
  ...rest
}) => {
  const elsPerPage = currentMemberIsAdmin ? 100 : 10;
  const { xs, sm, md, lg, xl } = colsPerBreakpoint;
  const [items, setItems] = useState(properties);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [preloading, setPreloading] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [displayedProperties, setDisplayedProperties] = useState(showPagination ? [] : properties);

  const handleLoadMoreButtonClick = () => setCurrIndex(state => state + 1);

  const loadProperties = () => {
    setLoading(true);
    const start = currIndex * elsPerPage;
    const end = (start + elsPerPage) < propertiesLength ? start + elsPerPage : propertiesLength;
    setDisplayedProperties(state => {
      setLoading(false);
      return [...state, ...items.slice(start, end)];
    });
  };

  const loadNextItemPage = () => {
    // all items are preloaded or preloading
    if (!(items.length < propertiesLength) || preloading) return;

    setPreloading(true);
    let nextPageUrl = `${url}&page=${page}`;
    if (currentMemberIsAdmin) nextPageUrl = `${nextPageUrl}&a=1`; // admin mode
    axios.get(nextPageUrl).then(response => {
      const { properties } = response.data;
      const newItems = [...items, ...properties];

      setItems(newItems);
      setPage(page + 1);

      setPreloading(false);
    });
  };

  useEffect(() => showPagination && loadNextItemPage(), [currIndex]);

  useEffect(() => {
    if (showPagination) { loadProperties() }
  }, [currIndex]);

  useEffect(() => {
    if (showPagination && displayedProperties.length > elsPerPage) {
      eventBus(window, 'property_cards:update');
    }
  }, [displayedProperties]);

  return (
    <ThemeProvider>
      <Grid spacing={gridSpacing || [0, 1]} container sx={{ p: [0, 1] }} {...rest}>
        {displayedProperties.map(property => (
          <Grid
            key={property.id}
            item
            xs={xs || 12}
            sm={sm}
            md={md}
            lg={lg || 6}
            xl={xl}
            className="property-item"
          >
            <PropertyCard
              property={property}
              currentMemberIsAdmin={currentMemberIsAdmin}
              availableOnRequest={property.availability_on_request}
              rounded
              divider
              casatb={casatb}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 2
            }}>
            {showPagination && (
              <>
                <Box>
                  <Typography>
                    {
                      t('search_page.search.property_list.showing_progress_label')
                        .replace('showing_n', displayedProperties.length)
                        .replace('qnt_n', propertiesLength)
                    }
                  </Typography>
                </Box>
                <Box sx={{ width: 1 / 2, mx: 'auto', my: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    color="primary"
                    value={displayedProperties.length * 100 / propertiesLength}
                    sx={{ height: '0.5rem', borderRadius: '50rem' }}
                  />
                </Box>
                {displayedProperties.length < propertiesLength && (
                  <Box sx={{ mb: 2 }}>
                    <Button
                      disabled={!(displayedProperties.length < items.length) || loading}
                      onClick={handleLoadMoreButtonClick} size="large"
                    >
                      {t('search_page.search.property_list.load_more')}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PropertyList;
