import React, { useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import styled from 'styled-components';
import { ThemeProvider } from '../../contexts/theme';
import { SvgIcon } from '.';
import axios from 'axios';

const PhoneInput = ({ success, ...rest }) => {
  const [countryCode, setCountryCode] = useState('br');

  if (!rest.value || rest.value.length < 2) {
    axios.get('https://ipinfo.io/json')
      .then(res => {
        const { country } = res.data;
        setCountryCode(country.toLowerCase());
      })
      .catch(() => setCountryCode('br'));
  }

  return (
    <ThemeProvider>
      <StyledInput
        {...rest}
        disableAreaCodes
        defaultCountry={countryCode}
        variant="outlined"
        error={rest.error}
        FormHelperTextProps={{ sx: { mx: 0 } }}
        helperText={
          (rest.error &&
            <>
              <SvgIcon name="error_input_icon" mr={0.375} />
              {rest.helperText}
            </>
          ) || (success &&
            <>
              <SvgIcon name="success_input_icon" mr={0.375} />
              {rest.helperText}
            </>
          )
        }
      />
    </ThemeProvider>
  );
};

const StyledInput = styled(MuiPhoneNumber)`
  background-color: ${({ theme }) => theme.palette.background.default};

  .MuiPhoneNumber-flagButton {
    width: min-content;
    &:focus {
      border-radius: 0;
      outline: none;
    }
  }

  .MuiButtonBase-root * {
    width: -webkit-fill-available;
  }
`;

export default PhoneInput;
