import { Snackbar } from '@mui/material';
import React, { useState } from 'react';
import Check from '../../../images/react_icons/Check';
import Info from '../../../images/react_icons/Info';
import Button from './Button';
import IconButton from './IconButton';
import Paper from './Paper';
import SvgIcon from './SvgIcon';
import Typography from './Typography';

const Toast = ({
  type = 'success',
  icon = undefined,
  open,
  text,
  buttonText,
  onClose,
  onClickButton,
  onAfterClickButton,
  showCloseIcon = true
}) => {
  const DisplayIcon = icon || (type === 'success' ? Check : Info);
  const [loading, setLoading] = useState(false);

  const onSnackbarClose = () => {
    onClose && onClose();
  };

  const onClickActionButton = async() => {
    if (loading) return;

    setLoading(true);
    await onClickButton();
    setLoading(false);

    onSnackbarClose();
    onAfterClickButton && onAfterClickButton();
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ top: 78 }} onClose={onSnackbarClose} open={open}>
      <Paper sx={{ display: 'flex', borderRadius: '100px', p: 0.5, alignItems: 'center', position: 'fixed', top: 78 }}>
        <DisplayIcon color={type} sx={{ height: 20, width: 20 }} />
        <Typography variant="body2" component="span" sx={{ pl: 0.5, pr: 1.5 }}>{text}</Typography>
        {buttonText && (
          <Button
            sx={{ px: 0.5 }}
            onClick={onClickActionButton}
            $textSize="small"
            loading={loading}
            color={type}
            $compact={true}
            $light={true}
            $noWrap={true}
          >
            {buttonText}
          </Button>
        )}
        {showCloseIcon && (
          <IconButton onClick={onSnackbarClose}>
            <SvgIcon name="x_mark" size={12} />
          </IconButton>
        )}
      </Paper>
    </Snackbar>
  );
};

export default Toast;
