import React from 'react';
import styled from 'styled-components';
import { SvgIcon, Link, Grid } from '../elements';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';

const ContactUsContacts = ({ texts }) => {
  return (
    <ThemeProvider>
      <ContactUsContentWrapper>
        <StyledGrid>{texts.description}</StyledGrid>
        <SvgIcon name="envelope" size="18" />
        <StyledLink underline="hover" href={`mailto:${texts.mailto}`}>{texts.mailto}</StyledLink>
        <br />
        <SvgIcon name="phone" size="18" />
        <StyledLink underline="hover" href={`tel:${t('contact.property_owner_phone')}`}>{t('contact.corporate_phone_formatted')}</StyledLink>
        <br />
        <SvgIcon name="whatsapp" size="18" />
        <StyledLink
          underline="hover"
          rel="nofollow"
          className="whatsapp-link whatsapp-link-menu"
          href={`/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.support')}`}
          target="_blank"
        >
          {t('navigation.support.whatsapp_contact_formatted')}
        </StyledLink>
      </ContactUsContentWrapper>
    </ThemeProvider>
  );
};

const ContactUsContentWrapper = styled.div`
  margin-top: 3rem;
  width: auto;
  height: auto;
  a:hover {
    color: #555656;
  }
`;

const StyledGrid = styled(Grid)`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1rem;
  color: #555656;
  text-align: left;
  padding-left: 0.3rem
`;

export default ContactUsContacts;
