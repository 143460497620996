import React from 'react';
import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';
import { SvgIcon } from '.';

const Accordion = ({
  accordionSummaryContent,
  accordionDetailsContent,
  accordionSubtitle = '',
  summaryProps,
  summaryAriaControls,
  summaryId,
  expandIcon,
  noSummaryIcon,
  ...rest
}) => {
  return (
    <ThemeProvider>
      <StyledAccordion {...rest}>
        <AccordionSummary
          sx={{ px: 1, py: 0.85 }}
          expandIcon={
            expandIcon || (<SvgIcon name="arrow_right_black" />)
          }
          aria-controls={summaryAriaControls}
          id={summaryId}
          {...summaryProps}
        >
          <AccordionTextWrapper>
            {accordionSummaryContent}
            <AccordionSubtitle>{accordionSubtitle}</AccordionSubtitle>
          </AccordionTextWrapper>
        </AccordionSummary>
        <AccordionDetails
          sx={{ p: 1, pt: 0 }}
        >
          {accordionDetailsContent}
        </AccordionDetails>
      </StyledAccordion>
    </ThemeProvider>
  );
};

const StyledAccordion = styled(MuiAccordion)`
  box-shadow: ${({ consistentShadow }) => consistentShadow && '0px 0px 2px rgba(96, 97, 112, 0.16)'} ;
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content{
    margin: 0;
  }
`;

const AccordionTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const AccordionSubtitle = styled(Box).attrs({
  color: 'primary.main'
})`
  font-weight: 400;
  font-size: 1rem;
  opacity: 0.7;
  font-family: 'Poppins';
`;

export default Accordion;
