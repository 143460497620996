import React from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { ThemeProvider } from '../../contexts/theme';
import { SwiperContainer, SwiperPagination } from './Swiper.styles';

SwiperCore.use([Pagination, Navigation]);

const Swiper = ({ slides, styles = {}, disablePagination, ...rest }) => {
  const uuid = (Math.random() + 1).toString(36).substring(2).replace(/[0-9]/g, '');
  const pagination = {
    clickable: true,
    renderBullet: (i, className) => `<span onclick="function(e){e.stopPropagation;}" class="${className}"></span>`
  };
  return (
    <ThemeProvider>
      <SwiperContainer
        spaceBetween={200}
        pagination={disablePagination ? false : {
          ...pagination,
          el: `.${uuid}`
        }}
        loop={true}
        $styles={styles}
        {...rest}
      >
        {!disablePagination && (
          <SwiperPagination className={uuid} $styles={styles} />
        )}
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            {slide}
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </ThemeProvider>
  );
};

export default Swiper;
