import React from 'react';
import styled, { css } from 'styled-components';
import { AppBar as MuiAppBar } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';

const AppBar = React.forwardRef(({
  children,
  disableShadow,
  retracted,
  retractionPercentage,
  ...rest
}, ref) => {
  return (
    <ThemeProvider>
      <StyledAppBar
        ref={ref}
        $disableShadow={disableShadow}
        $retracted={retracted}
        $retractionPercentage={retractionPercentage}
        {...rest}
      >
        {children}
      </StyledAppBar>
    </ThemeProvider>
  );
});

const StyledAppBar = styled(MuiAppBar)`
  box-shadow: ${({ $disableShadow }) => $disableShadow ? 'none' : '0px 0.5px 2px rgba(41, 45, 50, 0.16)'};
  ${({ background, theme }) => css`
    background-color: ${background || theme.palette.secondary.main};
  `}
  ${({ $retracted, $retractionPercentage }) => $retracted && css`
    transform: ${`translateY(-${$retractionPercentage || 100}%)`};
  `}
  transition: transform 0.5s ease, background-color 200ms ease;
`;

export default AppBar;
