import styled from 'styled-components';
import { Box, SvgIcon, Typography } from '../elements';

export const ArrowDown = styled(SvgIcon).attrs({
  name: 'arrow_down2',
  opacity: 0.7,
  width: '14px',
  height: '14px',
  margin: 'auto 0'
})``;

export const ArrowRight = styled(SvgIcon).attrs({
  name: 'arrow_right_black',
  opacity: 0.7,
  width: '14px',
  height: '14px',
  margin: 'auto 0'
})``;

export const CentralizedBox = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '3rem',
  paddingTop: '2rem'
})``;

export const NoReservationText = styled(Typography).attrs({
  color: 'primary.100',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem'
})``;

export const StaysText = styled(Typography).attrs({
  display: 'flex',
  justifyContent: 'space-between',
  color: 'primary.100',
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '24px',
  padding: '0.5rem 0'
})``;
