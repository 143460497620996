import React from 'react';
import { Typography } from '../elements';

const ContentOrderedList = (props) => {
  return (
    <ol>
      {props.items.map((item, index) => (
        <li key={index}>
          <Typography variant="body1" paragraph dangerouslySetInnerHTML={{ __html: `${item}` }}/>
        </li>
      ))}
    </ol>
  );
};

export default ContentOrderedList;
