import React from 'react';
import Button from './Button';
import { ButtonGroup as MuiButtonGroup } from '@mui/material';

const ButtonGroup = ({ buttons = [], buttonProps = {}, ...rest }) => {
  return (
    <MuiButtonGroup {...rest}>
      {buttons.map((button, i) => (
        <Button key={i} {...buttonProps} {...button} />
      ))}
    </MuiButtonGroup>
  );
};

export default ButtonGroup;
