import React, { useRef, useState, useEffect } from 'react';
import { useDeepCompareEffectForMaps } from '../../../hooks/useDeepCompareEffectForMaps';
import { Box } from '../../elements';
import { Wrapper } from '@googlemaps/react-wrapper';

const Map = ({ children, mapWrapperProps, ...mapOptions }) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(new window.google.maps.Map(mapRef.current, {}));
    }
  }, [map, mapRef.current]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(mapOptions);
    }
  }, [map, mapOptions]);

  return (
    <Box ref={mapRef} id="map" {...mapWrapperProps}>
      {React.Children.map(children, (mapChild) => {
        if (React.isValidElement(mapChild)) {
          return React.cloneElement(mapChild, { map });
        }
      })}
    </Box>
  );
};

const GoogleMap = (props) => {
  const MapApiKey = window.TABAS_MAPS_JAVASCRIPT_API_KEY;

  return (
    <Wrapper apiKey={MapApiKey}>
      <Map {...props} />
    </Wrapper>
  );
};

export default GoogleMap;
