import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Drawer,
  Link
} from '../elements';
import {
  SubOptionContainer,
  MenuItem,
  MenuItemLabel,
  SmallTitle,
  BottomText,
  SearchWhiteIcon,
  SearchIconAdornment,
  DropIconAdornment,
  LargeSearchContainer,
  LargeTextField,
  SearchBtn,
  SearchWrapper
} from './SearchField.styles';
import { DropdownMenu } from '.';
import { t } from '../../../js/common/translations';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const CitiesAndNeighbourhoods = ({ options }) => (
  <>
    {options.map((groupItem, groupIndex) => (
      <SubOptionContainer key={groupIndex} className={groupItem[0].slug} sx={{ gridArea: groupItem[0].slug }}>
        {groupItem.map((item, index) => {
          const { description, highlight, ...rest } = item;
          const href = item.href || `${item.path}?search[neighbourhoods][]=${item.value}`;
          return (
            <span key={index}>
              <MenuItem
                key={index}
                button
                href={href}
                {...rest}
                component={Link}
              >
                <MenuItemLabel $highlight={highlight}>
                  {item.description}
                </MenuItemLabel>
              </MenuItem>
            </span>
          );
        })}
      </SubOptionContainer>
    ))}
  </>
);

const DefaultSearchField = ({ options, isMobile, setOpenDrawer }) => {
  return (
    <>
      {(!options?.length || isMobile) ? (
        <TextField
          variant="outlined"
          placeholder={t('home_page.hero.search_field.placeholder')}
          onClick={options?.length ? () => setOpenDrawer(true) : undefined}
          data-bs-toggle={options?.length ? '' : 'modal'}
          data-bs-target={options?.length ? '' : '#modalCity'}
          InputProps={{
            id: 'header_search',
            readOnly: true,
            endAdornment: <SearchIconAdornment />,
            sx: {
              legend: {
                display: 'none'
              },
              fieldset: {
                top: 0
              },
              input: {
                cursor: 'pointer'
              }
            }
          }}
          size="small"
        />
      ) : (
        <DropdownMenu
          anchorEl={TextField}
          anchorElProps={{
            variant: 'outlined',
            placeholder: t('home_page.hero.search_field.placeholder'),
            InputProps: {
              id: 'header_search',
              readOnly: true,
              endAdornment: <SearchIconAdornment />,
              sx: {
                legend: {
                  display: 'none'
                },
                fieldset: {
                  top: 0
                },
                input: {
                  cursor: 'pointer'
                }
              }
            },
            size: 'small'
          }}
          menuItems={options}
          menuProps={{
            sx: {
              mt: 0.75
            }
          }}
          variant="home"
        >
          <CitiesAndNeighbourhoods options={options} />
        </DropdownMenu>
      )}
    </>
  );
};

const LargeSearchField = ({ options, isMobile, setOpenDrawer }) => {
  return (
    <LargeSearchContainer>
      {(!options?.length || isMobile) ? (
        <LargeTextField
          onClick={options?.length ? () => setOpenDrawer(true) : undefined}
          data-bs-toggle={options?.length ? '' : 'modal'}
          data-bs-target={options?.length ? '' : '#modalCity'}
          label={t('home_page.hero.search_field.label')}
          placeholder={t('home_page.hero.search_field.placeholder')}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            id: 'hero_search',
            readOnly: true,
            endAdornment: <DropIconAdornment />
          }}
        />
      ) : (
        <DropdownMenu
          anchorEl={LargeTextField}
          anchorElProps={{
            label: t('home_page.hero.search_field.label'),
            placeholder: t('home_page.hero.search_field.placeholder'),
            InputLabelProps: {
              shrink: true
            },
            InputProps: {
              id: 'hero_search',
              readOnly: true,
              endAdornment: <DropIconAdornment />
            }
          }}
          menuItems={options}
          menuProps={{
            sx: {
              '& .MuiPaper-root': {
                borderRadius: '4px !important'
              },
              mt: 1.35,
              ml: -0.8,
              '& .MuiMenu-paper': {
                '@media screen and (max-height: 768px)': {
                  height: '220px'
                },
                '&::-webkit-scrollbar': {
                  width: '6px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'secondary.35'
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '50rem',
                  backgroundColor: 'primary.80'
                }
              }
            }
          }}
          variant="home"
        >
          <CitiesAndNeighbourhoods options={options} />
        </DropdownMenu>
      )}
      <SearchBtn type="button" data-bs-toggle='modal' data-bs-target='#modalCity'>
        <Typography sx={{ typography: ['caption', 'button'], pl: 0.75 }}>
          {t('search.submit')}
        </Typography>
        <SearchWhiteIcon ml={0.5} pr={0.5} size='28' sx={{ height: 20 }} />
      </SearchBtn>
    </LargeSearchContainer>
  );
};

const SearchField = ({
  currentValue = '',
  size = 'small',
  variant,
  options = [],
  ...rest
}) => {
  const formRef = useRef(null);
  const [state, setState] = useState({
    description: currentValue,
    value: '',
    path: null
  });

  useEffect(() => {
    if (state.path) {
      formRef.current.submit();
    }
  }, [state.path]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = ['xs'].includes(useBreakpoint());

  return (
    <SearchWrapper
      ref={formRef}
      component="form"
      action={state.path || t('apartments_search_path')}
    >
      <input
        value={state.value}
        name="search[neighbourhoods][]"
        style={{ display: 'none' }}
        readOnly
      />
      {variant === 'hero' ? (
        <LargeSearchField
          options={options}
          onChange={item => setState({
            description: item.description,
            value: item.value,
            path: item.path
          })}
          isMobile={isMobile}
          setOpenDrawer={setOpenDrawer}
          {...rest}
        />
      ) : (
        <DefaultSearchField
          options={options}
          onChange={item => setState({
            description: item.description,
            value: item.value,
            path: item.path
          })}
          isMobile={isMobile}
          setOpenDrawer={setOpenDrawer}
          {...rest}
        />
      )}
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        titleAlign="flex-start"
        headerContent={
          <Box>
            <SmallTitle>{t('home_page.hero.search_field.small_title')}</SmallTitle>
            <BottomText>{t('home_page.hero.search_field.label')}</BottomText>
          </Box>
        }
        sx={{ '& .MuiDialogTitle-root': { backgroundColor: 'background.100' } }}
      >
        <Box display="flex" flexDirection="column">
          <CitiesAndNeighbourhoods options={options} />
        </Box>
      </Drawer>
    </SearchWrapper>
  );
};

export default SearchField;
