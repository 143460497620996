import { useState, useEffect } from 'react';

export function useScroll() {
  const [scrollState, setScrollState] = useState({
    direction: 'down',
    value: 0,
    currentYPos: window.scrollY
  });

  const handleScroll = () => {
    setScrollState(state => {
      const scrollAmount = window.scrollY - state.currentYPos;
      const direction = scrollAmount > 0 ? 'down' : 'up';
      return {
        direction,
        value: direction === state.direction ? state.value + Math.abs(scrollAmount) : 0,
        currentYPos: window.scrollY
      };
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollState;
}
