import React from 'react';
import styled from 'styled-components';
import { FormControl as MuiFormControl, FormHelperText } from '@mui/material';
import { SvgIcon } from '.';

const FormControl = ({ formHelperText, formHelperTextProps, children, ...rest }) => {
  return (
    <CustomFormControl {...rest}>
      {children}
      {
        formHelperText &&
        <FormHelperText sx={{ mx: 0, display: rest.error ? 'inline' : 'none' }}>
          <SvgIcon name="error_input_icon" mr={0.375} />
          {formHelperText}
        </FormHelperText>
      }
    </CustomFormControl>
  );
};

const CustomFormControl = styled(MuiFormControl)``;

export default FormControl;
