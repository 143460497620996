import React from 'react';
import Swiper from './Swiper';
import { Box } from '../elements';
import PropertyCard from './PropertyCard';

import { ListContainer } from './MapPropertyList.styles';

const MapPropertyList = ({ properties, mobile }) => {
  const mapProperties = properties.map(property => ({
    ...property,
    photos: property.photos?.length ? [property.photos[0]] : []
  }));

  const PropertiesElements = mapProperties.map(property => (
    <PropertyCard key={property.id} property={property} mapVersion={true} availableOnRequest={property.availability_on_request}/>
  ));

  return mobile ? (
    <Box
      sx={{
        '.swiper-slide': {
          height: 'auto !important'
        },
        a: {
          height: 1
        }
      }}
    >
      <Swiper
        slides={PropertiesElements}
        loop={false}
        pagination={false}
        spaceBetween={5}
        slidesPerView={properties.length === 1 ? 1 : 1.2}
        observer={true}
        observeParents={true}
      />
    </Box>
  ) : (
    <ListContainer>
      {PropertiesElements}
    </ListContainer>
  );
};

export default MapPropertyList;
