import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';

export function useBreakpoint() {
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
