import React from 'react';
import { Box, SvgIcon, Typography } from '../elements';
import styled from 'styled-components';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';

const SloganText = styled(Typography)`
  font-family: 'Cambon';
  color: ${({ theme }) => theme.palette.secondary.light};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 29px;
`;

const LogoWithSlogan = () => {
  return (
    <ThemeProvider>
      <Box sx={{
        marginTop: '1rem',
        gap: '0.5rem',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Box sx={{
          alignSelf: 'center'
        }}>
          <SvgIcon size="130" name="logo_white" />
        </Box>
        <Box>
          <SloganText>
            {t('footer.slogan')}
          </SloganText>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LogoWithSlogan;
