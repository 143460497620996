import React, { useState } from 'react';
import { Box } from '../elements';
import { Container, TabsWrapper, Tab, Content } from './Tabs.styles';
import { Tabs as MUITabs, Tab as MUITab } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';

const Tabs = ({ data, showTab = true, currentTab = 0, onTabClick, size = 'large', scroll = false }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider>
      <Container>
        {showTab && <TabsWrapper maxWidth='100%'>
          {data.length > 2 ? (
            <MUITabs
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none'
                },
                '& .MuiTabs-indicatorSpan': {
                  display: 'none'
                },
                '.MuiTabs-scrollButtons.Mui-disabled': {
                  opacity: 0.2
                },
                px: '4px',
                borderRadius: '4px',
                height: '48px'

              }}
              disableRipple
              value={value}
              variant="scrollable"
              onChange={handleChange}
              scrollButtons="auto"
            >
              {data.map((tab, i) => (
                <MUITab sx={{ padding: 0, borderRadius: 0 }} key={i} disableRipple label={
                  <>
                    <Box display="flex" alignItems='center'>
                      <Tab
                        $active={currentTab === i}
                        $size={size}
                        variant="contained"
                        onClick={() => onTabClick(i)}
                        sx={{
                          borderRadius: 0,
                          backgroundColor: currentTab === i && 'secondary.light',
                          color: 'primary.main',
                          paddingTop: 0,
                          paddingBottom: 0,
                          '&:hover': {
                            backgroundColor: 'secondary.main'
                          },
                          '& .MuiTab-root': {
                            minHeight: '0 !important'
                          }
                        }}
                      >
                        {tab.label}
                      </Tab>
                      {data.length - 1 !== i && (
                        <Box sx={{ width: '2px', height: '18px', backgroundColor: 'primary.main', mx: '8px', opacity: '0.1' }} />
                      )}
                    </Box>
                  </>
                }
                />
              ))}
            </MUITabs>
          ) : (
            <MUITabs
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none'
                },
                '& .MuiTabs-indicatorSpan': {
                  display: 'none'
                },
                '.MuiTabs-scrollButtons.Mui-disabled': {
                  opacity: 0.2
                },
                borderRadius: '4px',
                height: '48px'

              }}
              disableRipple
              value={value}
              variant="scrollable"
              onChange={handleChange}
              scrollButtons="none"
            >
              <Box p='4px' display="flex">
                {data.map((tab, i) => (
                  <>
                    <Box key={i} display="flex" alignItems='center'>
                      <Tab
                        $active={currentTab === i}
                        $size={size}
                        variant="contained"
                        onClick={() => onTabClick(i)}
                        sx={{
                          borderRadius: 0,
                          color: 'primary.main',
                          paddingTop: 0,
                          paddingBottom: 0,
                          '& .MuiTab-root': {
                            opacity: '1 !important',
                            minHeight: '0 !important'
                          }
                        }}
                      >
                        {tab.label}
                      </Tab>
                      {data.length - 1 !== i && (
                        <Box sx={{ width: '2px', height: '18px', backgroundColor: 'primary.main', mx: '8px', opacity: '0.1' }} />
                      )}
                    </Box>
                  </>
                ))}
              </Box>
            </MUITabs>
          )}

        </TabsWrapper>}
        <Content>
          {data[currentTab].content}
        </Content>
      </Container >
    </ThemeProvider >
  );
};

export default Tabs;
