import React, { useState } from 'react';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import axios from 'axios';

import {
  Box,
  Typography,
  Dialog,
  TextField,
  Link,
  Drawer,
  Checkbox
} from '../elements';
import {
  DrawerWrapper,
  DialogWrapper,
  CancelButton,
  ConfirmButton,
  DatesWrapper,
  DatesTitle
} from './ExtendBookingDialog.styles';

const ExtendBookingDialog = ({
  maxDate,
  endDate,
  endDateFormatted,
  currentLocale,
  internalId,
  rextension,
  reservationId,
  openInfo = false,
  setOpenInfo
}) => {
  const [newDate, setNewDate] = useState(endDate);
  const [agreement, setAgreement] = useState(false);
  const [previewPrice, setPreviewPrice] = useState('');
  const moveOut = new Date(endDate);
  const parsedInputDate = moveOut.toISOString().split('T')[0];

  const newDateUnparsed = new Date(newDate);
  const newDateParsed = newDateUnparsed.setMinutes(
    newDateUnparsed.getMinutes() + newDateUnparsed.getTimezoneOffset()
  );

  const newMoveOutDate = new Intl.DateTimeFormat(currentLocale, { dateStyle: 'medium' }).format(
    new Date(newDateParsed)
  );

  const rentDays = new Date(newDateUnparsed - new Date(endDate)).getDate() - 1;

  const currentBreakpoint = useBreakpoint();

  const inputTypeDateProps = () => {
    const props = { min: parsedInputDate };
    if (maxDate) props.max = maxDate;
    return props;
  };

  const extensionFormData = () => {
    const formData = new FormData();

    formData.append('rextension[status]', 'Waiting');
    formData.append('rextension[contract_status]', 'waiting');
    formData.append('reservation_id', reservationId);
    formData.append('rextension[end_date(1i)]', newDate.split('-')[0]);
    formData.append('rextension[end_date(2i)]', newDate.split('-')[1]);
    formData.append('rextension[end_date(3i)]', newDate.split('-')[2]);

    return formData;
  };

  const createRextension = async() => {
    const rextensionFormData = extensionFormData();

    try {
      await axios.post(rextension.create_rextension_path, rextensionFormData);
    } catch (e) {
      console.error(e);
    }
  };

  const previewRextensionPrice = async() => {
    const rextensionFormData = extensionFormData();

    try {
      const response = await axios.post(rextension.preview_rextension_path, rextensionFormData);
      setPreviewPrice(response.data.total);
    } catch (e) {
      console.error(e);
    }
  };

  if (!(newDate === endDate)) {
    previewRextensionPrice();
  }

  return (
    <ThemeProvider>
      {['xs', 'sm'].includes(currentBreakpoint) ? (
        <DrawerWrapper>
          <Drawer
            anchor="bottom"
            onClose={() => setOpenInfo(false)}
            open={openInfo}
            headerContent={
              <Typography variant="h2">
                {t('detailed_booking_page.extend_move_out.title')}
              </Typography>
            }
          >
            <Box p={1.5}>
              <Box
                display="flex"
                sx={{
                  justifyContent: 'space-between'
                }}
              >
                <DatesWrapper>
                  <DatesTitle>{t('detailed_booking_page.extend_move_out.current')}</DatesTitle>
                  <Typography component="span">{endDateFormatted}</Typography>
                </DatesWrapper>
                <DatesWrapper>
                  <DatesTitle component="span">
                    {t('detailed_booking_page.extend_move_out.new')}
                  </DatesTitle>
                  <Typography>{newDate === endDate ? '-' : newMoveOutDate}</Typography>
                </DatesWrapper>
              </Box>
              <Box my={1.5} width={1}>
                <TextField
                  id='extended_move_out_dates'
                  type="date"
                  label={t('detailed_booking_page.extend_move_out.new_date')}
                  fullWidth
                  onChange={(e) => e.target.value && setNewDate(e.target.value)}
                  InputProps={{ inputProps: inputTypeDateProps() }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="h5" fontWeight="bold" my={0.5}>
                  {t('detailed_booking_page.extend_move_out.confirm_title')}
                </Typography>
                <Typography variant="span" mb={0.5}>
                  {t('detailed_booking_page.extend_move_out.confirm_description')}
                </Typography>
                <Box display="flex" alignItems="center" my={1}>
                  <Checkbox onChange={(e) => setAgreement(e.target.checked)} />
                  <Typography fontSize={14}>
                    {t('detailed_booking_page.extend_move_out.agreement_text')}
                    <Link href={t('privacy_policy_path')} ml={0.25}>
                      {t('detailed_booking_page.extend_move_out.privacy_policy')}
                    </Link>
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <ConfirmButton
                    disabled={!!(!agreement || newDate === endDate)}
                    href={
                      t('services_page.whatsapp_extend') +
                      t('services_page.whatsapp_extend_booking') +
                      t('services_page.whatsapp_extend_from') +
                      endDateFormatted.replaceAll(' ', '%20') +
                      t('services_page.whatsapp_extend_to') +
                      newMoveOutDate.replaceAll(' ', '%20') +
                      `${previewPrice !== null ? ' for the total of R$' + previewPrice : ''}` +
                      t('services_page.whatsapp_extend_apartment') +
                      internalId
                    }
                  >
                    {t('detailed_booking_page.extend_move_out.confirm')}
                  </ConfirmButton>
                  <CancelButton onClick={() => setOpenInfo(false)}>
                    {t('detailed_booking_page.extend_move_out.cancel')}
                  </CancelButton>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </DrawerWrapper>
      ) : (
        <DialogWrapper>
          <Dialog
            open={openInfo}
            size="md"
            onClose={() => setOpenInfo(false)}
            fullWidth
            headerContent={
              <Typography variant="h2">
                {t('detailed_booking_page.extend_move_out.title')}
              </Typography>
            }
          >
            <Box p={1.5}>
              <Box
                display="flex"
                sx={{
                  justifyContent: 'space-between'
                }}
              >
                <DatesWrapper>
                  <DatesTitle>{t('detailed_booking_page.extend_move_out.current')}</DatesTitle>
                  <Typography component="span">{endDateFormatted}</Typography>
                </DatesWrapper>
                <DatesWrapper>
                  <DatesTitle component="span">
                    {t('detailed_booking_page.extend_move_out.new')}
                  </DatesTitle>
                  <Typography>{newDate === endDate ? '-' : newMoveOutDate}</Typography>
                </DatesWrapper>
              </Box>
              <Box my={1.5} width={1}>
                <TextField
                  id='extended_move_out_dates'
                  type="date"
                  label={t('detailed_booking_page.extend_move_out.new_date')}
                  fullWidth
                  onChange={(e) => e.target.value && setNewDate(e.target.value)}
                  InputProps={{ inputProps: inputTypeDateProps() }}
                />
              </Box>
              {previewPrice && (
                <Box>
                  <hr />
                  <Typography mb={0.5}>{t('detailed_booking_page.rent_for', { days: rentDays })}</Typography>
                  <Box display="flex" justifyContent="space-between">
                    <Typography fontWeight={600}>Total:</Typography>
                    <Typography fontWeight={600}>{`R$ ${previewPrice}`}</Typography>
                  </Box>
                </Box>
              )}
              <Box display="flex" flexDirection="column">
                <Typography variant="h5" fontWeight="bold" my={0.5}>
                  {t('detailed_booking_page.extend_move_out.confirm_title')}
                </Typography>
                <Typography variant="span" mb={0.5}>
                  {t('detailed_booking_page.extend_move_out.confirm_description')}
                </Typography>
                <Box display="flex" alignItems="center" my={1}>
                  <Checkbox onChange={(e) => setAgreement(e.target.checked)} />
                  <Typography fontSize={14}>
                    {t('detailed_booking_page.extend_move_out.agreement_text')}
                    <Link href={t('privacy_policy_path')} ml={0.25}>
                      {t('detailed_booking_page.extend_move_out.privacy_policy')}
                    </Link>
                  </Typography>
                </Box>
                <ConfirmButton
                  disabled={!!(!agreement || newDate === endDate)}
                  href={
                    t('services_page.whatsapp_extend') +
                    t('services_page.whatsapp_extend_booking') +
                    t('services_page.whatsapp_extend_from') +
                    endDateFormatted.replaceAll(' ', '%20') +
                    t('services_page.whatsapp_extend_to') +
                    newMoveOutDate.replaceAll(' ', '%20') +
                    `${previewPrice !== null ? ' for the total of R$' + previewPrice : ''}` +
                    t('services_page.whatsapp_extend_apartment') +
                    internalId
                  }
                  onClick={() => createRextension()}
                >
                  {t('detailed_booking_page.extend_move_out.confirm')}
                </ConfirmButton>
                <CancelButton onClick={() => setOpenInfo(false)}>
                  {t('detailed_booking_page.extend_move_out.cancel')}
                </CancelButton>
              </Box>
            </Box>
          </Dialog>
        </DialogWrapper>
      )}
    </ThemeProvider>
  );
};

export default ExtendBookingDialog;
