import React from 'react';
import styled, { css } from 'styled-components';
import SvgIcon from './SvgIcon';
import {
  Box,
  Drawer as MuiDrawer,
  DialogTitle
} from '@mui/material';

const Drawer = ({
  children,
  headerContent,
  withBackButton,
  backUrl,
  headerContentProps,
  headerProps = {},
  contentProps = {},
  titleAlign = 'center',
  ...rest
}) => {
  const isWebview = window.isWebview;
  const sendMessage = () => window.ReactNativeWebView.postMessage('CloseButtonClicked');

  return (
    <CustomMuiDrawer {...rest}>
      {headerContent && (
        <DrawerHeader {...headerProps}>
          {withBackButton && (
            <HeaderCloseIconWrapper>
              <SvgIcon name="arrow_left_line" onClick={backUrl} size="26" />
            </HeaderCloseIconWrapper>
          )}
          <HeaderContentWrapper $titleAlign={titleAlign} {...headerContentProps}>
            {headerContent}
          </HeaderContentWrapper>
          <HeaderCloseIconWrapper>
            <SvgIcon name="close_icon" onClick={() => {
              rest.onClose();
              if (isWebview) {
                sendMessage();
              }
            }}
            />
          </HeaderCloseIconWrapper>
        </DrawerHeader>
      )}
      <DrawerContent {...contentProps}>
        {children}
      </DrawerContent>
    </CustomMuiDrawer>
  );
};

const CustomMuiDrawer = styled(MuiDrawer)`
  .MuiBackdrop-root {
    background-color: rgba(41, 45, 50, 0.3)
  }
  .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin: 0 0.25rem;
  }
`;

const DrawerHeader = styled(DialogTitle)`
  display: flex;
`;

const DrawerContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderContentWrapper = styled(Box)`
  margin-bottom: 0;
  display: flex;
  width: 100%;
  ${({ $titleAlign }) => $titleAlign && css`
    justify-content: ${$titleAlign};
  `}
`;

const HeaderCloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default Drawer;
