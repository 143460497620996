import React, { useState } from 'react';
import TextField from './TextField';

const MaskedTextField = ({ maskType, onChange, ...rest }) => {
  const [value, setValue] = useState('');

  const creditCard = (str) => str.length >= 20
    ? value
    : str.replace(/\D/g, '').replace(/(\d{4})(?=(\d{1})+(?!\d))/g, '$1 ');

  const cpf = (str) => str.length > 14
    ? value
    : str.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

  const cnpj = (str) => str.length > 15
    ? value
    : str.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1/$2')
      .replace(/(\d{4})\d+?$/, '$1');

  const onlyNumberWithMaxLength = (str, maxLength) => str.length > maxLength ? value : str.replace(/\D/g, '');

  const masks = {
    cpf,
    cnpj,
    credit_card: creditCard,
    cvv: (str) => onlyNumberWithMaxLength(str, 3),
    year: (str) => onlyNumberWithMaxLength(str, 4)
  };

  const handleValueChange = ({ target: { value } }) => {
    const formattedValue = masks[maskType](value);
    setValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <TextField
      {...rest}
      value={value}
      onChange={handleValueChange}
    />
  );
};

export default MaskedTextField;
