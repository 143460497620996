const inputValidator = (fields, validations) => {
  const emailValidator = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const passwordValidator = (password) => {
    return /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*[#?!@$%^&*-]?).{8,}$/.test(password);
  };

  const requiredValidator = (value) => typeof value === 'string' ? !!(value.replace(/\s+/, '')) : !!value;

  const phoneValidator = (value) => /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(value);

  const validationTypes = {
    email: emailValidator,
    password: passwordValidator,
    required: requiredValidator,
    phone: phoneValidator
  };

  const results = validations.map(([field, props]) => {
    if (typeof props.validation === 'function') {
      return {
        field,
        error: props.validation(fields[field]) ? false : (props.errorMessage || true)
      };
    } else {
      return {
        field,
        error: validationTypes[props.validation](fields[field]) ? false : (props.errorMessage || true)
      };
    }
  });

  return results.filter(r => !!r.error);
};

export default inputValidator;
