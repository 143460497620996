import styled, { css } from 'styled-components';
import {
  Box,
  Typography,
  Link
} from '../elements';

export const FooterTitle = styled(Typography).attrs({
  component: 'h5',
  thickness: 600
})`
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
`;

export const FooterContent = styled(Box)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.primary.light};
  padding-bottom: 0.5rem;
`;

export const FooterContainer = styled(Box).attrs({
  component: 'ul'
})`
  display: flex;
  padding-left: 0;
  flex-direction: column;
`;

export const FooterLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.75rem;
  line-height: 1.75rem;
  text-decoration: none;
  list-style-type: none;

  :focus:not(:focus-visible) {
    outline: 0px;
    color: ${({ theme }) => theme.palette.primary.light};
  }

  :focus {
    color: ${({ theme }) => theme.palette.primary.light};
    outline: 2px solid ${({ theme }) => theme.palette.primary['20']};
    border-radius: 0.25rem;
    width: fit-content;
    outline-offset: 0.25rem;
    height: fit-content;
    display: flex;
  }

  ${({ $policylink }) => $policylink && css`
    color: ${({ theme }) => theme.palette.primary.dark};
    display: flex;
    flex-direction: column;

    @media (min-width: 375px) {
      display: inline;
      :nth-child(1) {
        padding-left: 0;
      }
      :nth-child(2) {
        padding: 0 1em;
        border-color:  ${({ theme }) => theme.palette.primary.light};
        border-right: 1px solid;
        border-left: 1px solid;
      }
      :nth-child(3) {
        padding-right: 0;
      }
    }
    :nth-child(3) {
      padding-left: 1rem;
    }
  `}
`;

export const FooterSocialLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 0.75rem;
  text-decoration: none;

  :focus:not(:focus-visible) {
    outline: 0px;
    color: ${({ theme }) => theme.palette.primary.light};
  }

  :focus {
    color: ${({ theme }) => theme.palette.primary.light};
    outline: 2px solid ${({ theme }) => theme.palette.primary['20']};
    border-radius: 0.25rem;
    width: fit-content;
    outline-offset: 0.25rem;
  }
  `;

export const FooterAppLink = styled(Link)`
  text-decoration: none;

  :focus:not(:focus-visible) {
    outline: 0px;
  }

  :focus {
    color: ${({ theme }) => theme.palette.primary.light};
    outline: 2px solid ${({ theme }) => theme.palette.primary['20']};
    border-radius: 0.25rem;
    width: fit-content;
  }
  `;
