import styled from 'styled-components';
import { Box } from '../elements';

export const ListContainer = styled(Box)`
  width: 420px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  ::-webkit-scrollbar{
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb{
    background: #D3D4D4;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: #B3AFB3;
  }
  ::-webkit-scrollbar-track{
    background: #F0F0F0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
  }
`;
