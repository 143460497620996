import styled, { css } from 'styled-components';
import { Box, Button } from '../elements';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background[90]};
  overflow-x: auto;
  overflow-y: hidden;
`;

export const Tab = styled(Button).attrs(({ $active, $size }) => ({
  variant: $active ? 'contained' : null,
  size: $size,
  color: $active && 'secondary'
}))`
  ${({ $active }) => !$active && css`
    color: ${({ theme }) => theme.palette.primary[100].main};
  `}
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px !important;

  &:focus {
    outline: none;
  }
  `;

export const Content = styled(Box)`
  width: 100%;
  height: 100%;
`;
