import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@mui/material';

const IconButton = styled(MuiIconButton)`
  && {
    outline: none;
    &:hover {
      background-color: transparent;
    }   
  }
`;

export default IconButton;
