import styled from 'styled-components';
import {
  Box, FormControl, TextField
} from '../elements';

export const InputWrapper = styled(Box).attrs({
  display: 'flex',
  flexDirection: ['column', 'column', 'row']
})``;

export const LandlordTextField = styled(TextField).attrs({
  fullWidth: true,
  margin: 'dense'
})``;

export const SubmitWrapper = styled(FormControl).attrs({
  sx: {
    display: 'flex',
    bgcolor: ['secondary.light', 'secondary.light', 'secondary.main'],
    justifyContent: ['space-around', 'space-around', 'flex-end'],
    flexDirection: 'row',
    width: 1,
    py: 1
  }
})``;

export const ConfirmationWrapper = styled(Box).attrs({
  sx: {
    my: 4,
    display: 'flex',
    maxWidth: { xs: 1, md: 360 },
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: ['auto', 1],
    textAlign: 'center'
  }
})``;
