import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { ThemeProvider } from '../../contexts/theme';
import inputValidator from '../../utils/inputValidator';
import { t } from '../../../js/common/translations';
import {
  Box,
  Link,
  Button,
  TextField,
  PhoneInput,
  Checkbox,
  Typography,
  SvgIcon,
  FormControl,
  FormControlLabel
} from '../../UI/elements';

const INITIAL_VALUES = {
  phone: '',
  full_name: '',
  email: '',
  comment: '',
  agreement: false
};

const TenantForm = ({ onCancel, url, siteKey }) => {
  const formRef = useRef(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [generalForm, setGeneralForm] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);

  const formValidations = [
    ['full_name', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phone', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phone', { validation: 'phone', errorMessage: t('form_errors.phone_invalid') }],
    ['email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['comment', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['agreement', { validation: 'required', errorMessage: t('form_errors.agreement_checkbox_not_checked') }]
  ];

  const handleChange = (inputName) => ({ target: { type, value, checked } }) => {
    const inputValue = type === 'checkbox' ? checked : value;
    const validate = {
      [inputName]: inputValue
    };
    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));

    setGeneralForm(state => ({
      ...state,
      [inputName]: inputValue
    }));
    setFieldErrors(errors);
  };

  const getErrorMessage = (inputName) => {
    return fieldErrors.filter(el => el.field === inputName)[0]?.error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = inputValidator(generalForm, formValidations);

    if (errors.length) {
      setFieldErrors(errors);
      return;
    }

    const data = new FormData(formRef.current);
    submitForm(data);
  };

  const submitForm = async data => {
    setLoading(true);

    await axios.post(url, data)
      .then(() => {
        setShowSuccess(true);
        setGeneralForm(INITIAL_VALUES);
      })
      .catch(err => {
        const errMessages = err?.response?.data?.error;
        if (errMessages?.length) { setErrorMessage(errMessages[0]) }
      });

    setLoading(false);
  };

  if (showSuccess) {
    return (
      <Box
        className='general-contact-modal-tym'
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: ['auto', 1],
          maxWidth: { xs: 1, md: 360 },
          textAlign: 'center'
        }}
      >
        <SvgIcon
          name="success_large"
          className="general-contact-modal-tym-img"
          size={100}
        />
        <Typography sx={{ m: 2 }} variant="h5">
          {t('companies_page.contact_form.success_message')}
        </Typography>
        <Button
          href="/"
          variant="contained"
          size="large"
          sx={{ mt: 1 }}
        >
          {t('companies_page.contact_form.success_button')}
        </Button>
      </Box>
    );
  }

  return (
    <ThemeProvider>
      <Box
        onSubmit={handleSubmit}
        data-type="json"
        remote
        component="form"
        ref={formRef}
        px={1}
      >
        <Box>
          <TextField
            onChange={handleChange('full_name')}
            label={t('contact_us.general.form.fields.full_name')}
            className="general-contact-modal-full-name"
            helperText={getErrorMessage('full_name')}
            name='general_contact[full_name]'
            error={!!getErrorMessage('full_name')}
            value={generalForm.full_name}
            sx={{ my: 0.75 }}
            fullWidth
          />
          <PhoneInput
            autoFormat={false}
            fullWidth
            helperText={getErrorMessage('phone')}
            name='general_contact[phone]'
            error={!!getErrorMessage('phone')}
            className="general-contact-modal-phone"
            value={generalForm.phone}
            onChange={(value) => handleChange('phone')({ target: { value } })}
            sx={{ my: 0.75 }}
            label={t('contact_us.general.form.fields.phone')}
          />
          <TextField
            label="E-mail"
            helperText={getErrorMessage('email')}
            className="general-contact-modal-email"
            error={!!getErrorMessage('email')}
            value={generalForm.email}
            name='general_contact[email]'
            sx={{ my: 0.75 }}
            onChange={handleChange('email')}
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            label={t('contact_us.general.form.fields.comment')}
            multiline
            className="general-contact-modal-comment"
            name='general_contact[comment]'
            helperText={getErrorMessage('comment')}
            error={!!getErrorMessage('comment')}
            onChange={handleChange('comment')}
            value={generalForm.comment}
            fullWidth
            sx={{ my: 0.75 }}
            rows={4}
          />
        </Box>
        <FormControl
          error={!!getErrorMessage('agreement')}
          className="general-contact-modal-check-terms"
          sx={{ pb: 1 }}
          formHelperText={getErrorMessage('agreement')}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="agreement"
                value={generalForm.agreement}
                onChange={handleChange('agreement')}
              />
            }
            label={(
              <Typography
                alignSelf="center"
                fontSize="0.75rem"
                color="primary.70"
              >
                {t('dictionary.policies_agreement_text')}
                <Link href={t('privacy_policy_path')} target="_blank" mx={0.25}>
                  {t('dictionary.policies_agreement_link')}
                </Link>
              </Typography>
            )}
          />
        </FormControl>
        {errorMessage && (
          <Box className="form-feedback mb-0">
            <Box
              className="alert alert-danger rounded-10 feedback-error"
              sx={{ padding: 10, fontSize: 14 }}
            >
              {errorMessage}
            </Box>
          </Box>
        )}
        <Box sx={{ my: 0.5 }}>
          <ReCAPTCHA sitekey={siteKey} />
        </Box>
        <Box
          sx={{
            pb: 1,
            pt: 1,
            display: 'flex',
            justifyContent: { xs: 'space-around', md: 'flex-end' }
          }}
        >
          {onCancel && (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => onCancel()}
              sx={{ mr: [0, 0, 2] }}
            >
              {t('companies_page.contact_form.cancel')}
            </Button>
          )}
          <Button
            id="form-submit"
            size="large"
            type="submit"
            className="general-contact-modal-get-in-touch"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          >
            {t('companies_page.contact_form.submit')}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TenantForm;
