import React from 'react';
import { Box, CardMedia, CardContent, Link } from '../elements';
import {
  ArrowRight,
  CalendarIcon,
  CardActionArea,
  CardStyled,
  CardTitle,
  CardBody,
  CardBottom,
  DateText,
  DividerStyled
} from './ReservationCard.styles';
import { t } from '../../../js/common/translations';

const disabledOpacity = 0.5;

const ReservationCard = ({ property, disabled = false, formUrl, mobile = false }) => {
  return (
    <CardStyled>
      <Box width="100%" height="100%" position="relative">
        <CardActionArea href={property.path} disabled={disabled}>
          <Box display="flex" width="100%">
            <CardMedia
              component="img"
              image={property.photo}
              sx={{
                borderRadius: '3px',
                height: '54px',
                width: '54px',
                ...(disabled && { opacity: disabledOpacity })
              }}
            />
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 0 0 1rem !important',
                ...(disabled && { opacity: disabledOpacity })
              }}
            >
              <Box>
                <CardTitle>
                  {property.details.internal_id} • {property.details.neighbourhood.name} • {property.details.city.name}
                </CardTitle>
                <CardBody>{property.details.listing_name}</CardBody>
                <CardBottom>
                  Ap {property.details.number} - {property.details.floor_number_text}{t('bookings_page.floor')}
                </CardBottom>
              </Box>
              <ArrowRight sx={{ ...(disabled && { display: 'none' }) }} />
            </CardContent>
          </Box>
          <DividerStyled />
          <Box display="flex" sx={{ justifyContent: 'space-between', width: '100%', flexDirection: mobile ? 'column' : 'row' }}>
            <Box display="flex" gap="0.5rem" sx={{ ...(disabled && { opacity: disabledOpacity }) }}>
              <CalendarIcon />
              <DateText>{property.start_date_formatted}</DateText>
              <DateText>{'>'}</DateText>
              <DateText>{property.end_date_formatted}</DateText>
            </Box>
            {!property.current_user_ownership && (
              <DateText $mobile={mobile} >
                {`${t('invitations_page.invited_by')} ${property.reservation_owner_name}`}
              </DateText>
            )}
          </Box>
        </CardActionArea>
        <Box>
          {(property.reservation_form_status !== 'Approved' &&
            property.reservation_form_status !== 'Submitted' &&
            property.reservation_form_url &&
            property.current_user_ownership) &&
            (
              <Link
                href={property.reservation_form_url}
                sx={{
                  position: { xs: 'static', md: 'absolute' },
                  right: { xs: 0, md: '70px' },
                  fontSize: '0.9rem',
                  bottom: { xs: 0, md: '78px' },
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    opacity: 0.8
                  }
                }}>
                <Box
                  bgcolor="primary.main"
                  py={0.25}
                  margin={{ xs: 0.5, md: 0 }}
                  px={0.5}
                  borderRadius="4px"
                  color="secondary.main"
                  textAlign="center"
                >
                  {t('reservation_form.complete_form')}
                </Box>
              </Link>
            )}
        </Box>
      </Box>
    </CardStyled>
  );
};

export default ReservationCard;
