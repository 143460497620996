import React from 'react';
import { ThemeProvider } from '../../contexts/theme';

const HtmlTranslation = ({ text }) => {
  return (
    <ThemeProvider>
      { <span dangerouslySetInnerHTML={{ __html: (text) }} /> }
    </ThemeProvider>
  );
};
export default HtmlTranslation;
