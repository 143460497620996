import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { t } from '../../../js/common/translations';
import {
  ArrowRight,
  CardLocationActionArea,
  CardLocationContent,
  Container,
  CustomBox,
  PaperBottom,
  PaperLink,
  DivisionsContainer,
  Division,
  PaperContainer,
  PaperTop,
  OuterDivider,
  InnerDivider,
  TextBottom,
  TextTop,
  DrawerContent,
  DrawerContentText,
  DrawerContentTextTop,
  DrawerContentTextBottom,
  DrawerTitle,
  DescriptionText,
  SubtitleText,
  CardLocation,
  CardLocationMedia,
  CardTitle,
  CardText,
  InfoChip,
  CopyableText
} from './ReservationDetails.styles';
import { ExtendBookingDialog } from '.';
import {
  Box,
  Dialog,
  Drawer,
  DrawerDark,
  IconButton,
  SvgIcon,
  Typography,
  Link
} from '../elements';
import copyToClipboard from '../../utils/copyToClipboard';
import { detectOperatingSystem } from '../../utils/detectOperatingSystem';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const ReservationDetails = ({ property, reservation, currentLocale, rextension, reservationInvite = {} }) => {
  const [openExtendInfo, setOpenExtendInfo] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(null);
  const deviceOperatingSystem = useMemo(() => detectOperatingSystem(), []);
  const isMobile = ['xs', 'sm'].includes(useBreakpoint());

  return (
    <ThemeProvider>
      <Container>
        <Box>
          {(reservation.reservation_form_url &&
            reservation.reservation_form_status !== 'Approved' &&
            reservation.reservation_form_status !== 'Submitted' &&
            reservationInvite.ownership === 'owner') &&
            (
              <Link href={reservation.reservation_form_url} sx={{
                fontSize: '0.9rem',
                textDecoration: 'none',
                fontWeight: 'bold',
                '&:hover': {
                  opacity: 0.8
                }
              }}>
                <Box
                  bgcolor='primary.main'
                  py={0.25}
                  mt={0.75}
                  mb={1}
                  px={0.5}
                  textAlign="center"
                  borderRadius='4px'
                  color="secondary.main"
                >
                  {t('reservation_form.complete_form')}
                </Box>
              </Link>
            )}
        </Box>
        <PaperContainer>
          <PaperTop>
            <Box>
              <TextTop>{t('bookings_page.check_in')}</TextTop>
              <TextBottom>{reservation.startDateFormatted}</TextBottom>
            </Box>
            <Box>
              <ArrowRight />
            </Box>
            <Box textAlign='end'>
              <TextTop>{t('bookings_page.check_out')}</TextTop>
              <TextBottom>{reservation.endDateFormatted}</TextBottom>
            </Box>
          </PaperTop>
          {reservationInvite.ownership === 'owner' && (reservation.extendable ? (
            <PaperBottom onClick={() => setOpenExtendInfo(true)} sx={{ cursor: 'pointer' }}>
              <Box id='extended_move_out'>{t('detailed_booking_page.extend')}</Box>
              <ArrowRight />
            </PaperBottom>
          ) : (
            <PaperBottom>
              <Box id='extended_move_out'>{t('detailed_booking_page.extend_move_out.extension_blocked')}</Box>
            </PaperBottom>
          ))}
        </PaperContainer>
        <OuterDivider />
        <TextTop padding="0.5rem 0">{t('detailed_booking_page.apartment_access')}</TextTop>
        <PaperContainer>
          <DivisionsContainer>
            {reservationInvite.ownership === 'owner' && <Division onClick={() => setOpenMoreInfo('doorcode')}>
              <Box>
                <TextTop>{t('detailed_booking_page.door_lock')}</TextTop>
                <TextBottom>{t('detailed_booking_page.door_lock_description')}</TextBottom>
              </Box>
              <ArrowRight />
            </Division>}
            <OuterDivider />
            <Division onClick={() => setOpenMoreInfo('wifi')}>
              <Box>
                <TextTop>{t('detailed_booking_page.wifi')}</TextTop>
                <TextBottom>{t('detailed_booking_page.wifi_description')}</TextBottom>
              </Box>
              <ArrowRight />
            </Division>
            <OuterDivider />
            <Division onClick={() => setOpenMoreInfo('location')}>
              <Box>
                <TextTop>{t('detailed_booking_page.location')}</TextTop>
                <TextBottom>{t('detailed_booking_page.location_description')}</TextBottom>
              </Box>
              <ArrowRight />
            </Division>
          </DivisionsContainer>
        </PaperContainer>
        <OuterDivider />
        <TextTop padding="0.5rem 0">{t('detailed_booking_page.apartment_manual')}</TextTop>
        <PaperContainer>
          <DivisionsContainer sx={{ borderRadius: '4px 4px 0 0' }}>
            <Division onClick={() => setOpenMoreInfo('move')}>
              <Box>
                <TextTop>{t('detailed_booking_page.movein_moveout')}</TextTop>
                <TextBottom>{t('detailed_booking_page.movein_moveout_description')}</TextBottom>
              </Box>
              <ArrowRight />
            </Division>
            <OuterDivider />
            <Division onClick={() => setOpenMoreInfo('facilities')}>
              <Box>
                <TextTop>{t('detailed_booking_page.facilities')}</TextTop>
                <TextBottom>{t('detailed_booking_page.facilities_description')}</TextBottom>
              </Box>
              <ArrowRight />
            </Division>
            {(property.features.gym || property.features.sky_lounge || property.features.pool) && (
              <>
                <OuterDivider />
                <Division onClick={() => setOpenMoreInfo('commonarea')}>
                  <Box>
                    <TextTop>{t('detailed_booking_page.common_area')}</TextTop>
                    <TextBottom>{t('detailed_booking_page.common_area_description')}</TextBottom>
                  </Box>
                  <ArrowRight />
                </Division>
              </>
            )}
          </DivisionsContainer>
          <PaperLink href={`/member/reservations/${reservation.id}/manual.pdf`}>
            <PaperBottom id='pdf_manual_download'>
              <Box>{t('detailed_booking_page.download_manual')}</Box>
              <ArrowRight />
            </PaperBottom>
          </PaperLink>
        </PaperContainer>
        <OuterDivider />
        {reservationInvite.ownership === 'owner' && reservation.show_payment_section && (
          <>
            <TextTop padding="0.5rem 0">{t('detailed_booking_page.payment')}</TextTop>
            <PaperContainer>
              <DivisionsContainer>
                <Typography padding="1rem 0">
                  R${reservation.price + t('apartment_page.reservation_form.per_month')}
                </Typography>
              </DivisionsContainer>
            </PaperContainer>
          </>
        )}
      </Container>
      <br />
      <ExtendBookingDialog
        maxDate={reservation.maxDate}
        rextension={rextension}
        endDate={reservation.endDate}
        endDateFormatted={reservation.endDateFormatted}
        currentLocale={currentLocale}
        internalId={property.internal_id}
        reservationId={reservation.id}
        openInfo={openExtendInfo}
        setOpenInfo={setOpenExtendInfo}
      />
      {isMobile ? (
        <>
          <DrawerDark anchor="bottom" open={openMoreInfo === 'doorcode'} onClose={() => setOpenMoreInfo(null)}>
            <DoorCodeContent doorcodeText={reservation.doorcode_text} locktypeText={property.lock_type_text} />
          </DrawerDark>
          <DrawerDark anchor="bottom" open={openMoreInfo === 'wifi'} onClose={() => setOpenMoreInfo(null)}>
            <WifiContent wifiName={property.wifi_ssid} wifiPassword={property.wifi_password} />
          </DrawerDark>
          <Drawer
            anchor="bottom"
            open={openMoreInfo === 'location'}
            onClose={() => setOpenMoreInfo(null)}
            headerContent={
              <DrawerTitle>{t('detailed_booking_page.location_options.title')}</DrawerTitle>
            }
          >
            <LocationContent
              address={property.address}
              addressParameterized={property.address_parameterized}
              operatingSystem={deviceOperatingSystem}
            />
          </Drawer>
          <Drawer
            anchor="bottom"
            open={openMoreInfo === 'move'}
            onClose={() => setOpenMoreInfo(null)}
            headerContent={<DrawerTitle>{t('detailed_booking_page.movein_moveout')}</DrawerTitle>}
          >
            <OuterDivider sx={{ margin: '0 1.5rem' }} />
            <MoveInMoveOutContent property={property} moveInText={reservation.check_in_time_text} moveOutDate={reservation.check_out_time} />
          </Drawer>
          <Drawer
            anchor="bottom"
            open={openMoreInfo === 'facilities'}
            onClose={() => setOpenMoreInfo(null)}
            headerContent={<DrawerTitle>{t('detailed_booking_page.facilities')}</DrawerTitle>}
          >
            <OuterDivider sx={{ margin: '0 1.5rem' }} />
            <FacilitiesContent />
          </Drawer>
          <Drawer
            anchor="bottom"
            open={openMoreInfo === 'commonarea'}
            onClose={() => setOpenMoreInfo(null)}
            headerContent={<DrawerTitle>{t('detailed_booking_page.common_area')}</DrawerTitle>}
          >
            <OuterDivider sx={{ margin: '0 1.5rem' }} />
            <CommonAreaContent
              features={{
                gym: property.features.gym,
                sky_lounge: property.features.sky_lounge,
                pool: property.features.pool
              }}
            />
          </Drawer>
        </>
      ) : (
        <>
          <Dialog
            open={openMoreInfo === 'doorcode'}
            onClose={() => setOpenMoreInfo(null)}
            themeType='dark'
            overflowContent='visible'
          >
            <DoorCodeContent doorcodeText={reservation.doorcode_text} locktypeText={property.lock_type_text} />
          </Dialog>
          <Dialog
            open={openMoreInfo === 'wifi'}
            onClose={() => setOpenMoreInfo(null)}
            themeType='dark'
            overflowContent='visible'
          >
            <WifiContent wifiName={property.wifi_ssid} wifiPassword={property.wifi_password} />
          </Dialog>
          <Dialog
            open={openMoreInfo === 'location'}
            onClose={() => setOpenMoreInfo(null)}
            minwidth600="true"
            titleAlign="flex-start"
            headerContent={
              <Box>
                <TextBottom>{t('detailed_booking_page.location')}</TextBottom>
                <TextTop>{t('detailed_booking_page.location_options.title')}</TextTop>
              </Box>
            }
            sx={{ '& .MuiDialogTitle-root': { backgroundColor: 'background.100' } }}
          >
            <LocationContent
              address={property.address}
              addressParameterized={property.address_parameterized}
              operatingSystem={deviceOperatingSystem}
            />
          </Dialog>
          <Dialog
            open={openMoreInfo === 'move'}
            onClose={() => setOpenMoreInfo(null)}
            minwidth600="true"
            titleAlign="flex-start"
            headerContent={
              <Box>
                <TextBottom>{t('detailed_booking_page.apartment_manual')}</TextBottom>
                <TextTop>{t('detailed_booking_page.movein_moveout')}</TextTop>
              </Box>
            }
            sx={{ '& .MuiDialogTitle-root': { backgroundColor: 'background.100' } }}
          >
            <MoveInMoveOutContent property={property} moveInText={reservation.check_in_time_text} moveOutDate={reservation.check_out_time} />
          </Dialog>
          <Dialog
            open={openMoreInfo === 'facilities'}
            onClose={() => setOpenMoreInfo(null)}
            minwidth600="true"
            titleAlign="flex-start"
            headerContent={
              <Box>
                <TextBottom>{t('detailed_booking_page.apartment_manual')}</TextBottom>
                <TextTop>{t('detailed_booking_page.facilities')}</TextTop>
              </Box>
            }
            sx={{ '& .MuiDialogTitle-root': { backgroundColor: 'background.100' } }}
          >
            <FacilitiesContent />
          </Dialog>
          <Dialog
            open={openMoreInfo === 'commonarea'}
            onClose={() => setOpenMoreInfo(null)}
            minwidth600="true"
            titleAlign="flex-start"
            headerContent={
              <Box>
                <TextBottom>{t('detailed_booking_page.apartment_manual')}</TextBottom>
                <TextTop>{t('detailed_booking_page.common_area')}</TextTop>
              </Box>
            }
            sx={{ '& .MuiDialogTitle-root': { backgroundColor: 'background.100' } }}
          >
            <CommonAreaContent
              features={{
                gym: property.features.gym,
                sky_lounge: property.features.sky_lounge,
                pool: property.features.pool
              }}
            />
          </Dialog>
        </>
      )}
    </ThemeProvider>
  );
};

const DoorCodeContent = ({ doorcodeText, locktypeText }) => {
  return (
    <DrawerContent>
      <SvgIcon name="doorcode" size="126" />
      <DrawerContentText>
        {doorcodeText ? (
          <Box>
            <DrawerContentTextTop>
              {t('detailed_booking_page.door_lock_options')}
            </DrawerContentTextTop>
            <DrawerContentTextBottom component={'div'}>
              <Box mr="1rem" display="inline-block">{doorcodeText}</Box>
              <IconButton onClick={() => copyToClipboard(doorcodeText)}>
                <SvgIcon name="copy_icon_dark" size="15" />
              </IconButton>
            </DrawerContentTextBottom>
          </Box>
        ) : (
          <Box>
            <DrawerContentTextTop>
              {t('detailed_booking_page.door_lock')}
            </DrawerContentTextTop>
            <DrawerContentTextBottom>
              {locktypeText}
            </DrawerContentTextBottom>
          </Box>
        )}
      </DrawerContentText>
    </DrawerContent>
  );
};

const WifiContent = ({ wifiName, wifiPassword }) => {
  return (
    <DrawerContent>
      <SvgIcon name="reservation_wifi" size="126" />
      <DrawerContentText>
        <Box>
          <DrawerContentTextTop>{t('detailed_booking_page.wifi_options')}</DrawerContentTextTop>
          <DrawerContentTextBottom>{wifiName}</DrawerContentTextBottom>
          <DrawerContentTextTop marginTop={{ xs: 0, md: '1rem' }}>
            {t('detailed_booking_page.wifi_options2')}
          </DrawerContentTextTop>
          <DrawerContentTextBottom component={'div'}>
            <Box mr="1rem" display="inline-block">{wifiPassword}</Box>
            {wifiPassword && (
              <IconButton onClick={() => copyToClipboard(wifiPassword)}>
                <SvgIcon name="copy_icon_dark" size="15" />
              </IconButton>
            )}
          </DrawerContentTextBottom>
        </Box>
      </DrawerContentText>
    </DrawerContent>
  );
};

const LocationContent = ({ address, addressParameterized, operatingSystem }) => {
  return (
    <Box padding='0 1rem 2rem 1rem' gap='0.5rem'>
      <OuterDivider sx={{ marginBottom: '1rem' }} />
      <CardLocation>
        <CardLocationActionArea
          href={`https://maps.google.com/?q=${addressParameterized}`}
          target="_blank"
          rel="nofollow"
        >
          <CardLocationMedia>
            <SvgIcon name="googlemaps" size="34" />
          </CardLocationMedia>
          <CardLocationContent>
            <CardTitle>{t('detailed_booking_page.location_options.google_maps')}</CardTitle>
            <CardText>{t('detailed_booking_page.location_options.google_maps_text')}</CardText>
          </CardLocationContent>
        </CardLocationActionArea>
      </CardLocation>
      {!(operatingSystem === 'android') && (
        <CardLocation>
          <CardLocationActionArea
            href={`https://maps.apple.com/?address=${addressParameterized}`}
            target="_blank"
            rel="nofollow"
          >
            <CardLocationMedia>
              <SvgIcon name="applemaps" size="34" />
            </CardLocationMedia>
            <CardLocationContent>
              <CardTitle>{t('detailed_booking_page.location_options.apple_maps')}</CardTitle>
              <CardText>{t('detailed_booking_page.location_options.apple_maps_text')}</CardText>
            </CardLocationContent>
          </CardLocationActionArea>
        </CardLocation>
      )}
      <CardLocation>
        <CardLocationActionArea
          onClick={() => copyToClipboard(address)}
        >
          <CardLocationMedia>
            <SvgIcon name="copy_icon" size="24" />
          </CardLocationMedia>
          <CardLocationContent>
            <CardTitle>{t('detailed_booking_page.location_options.copy')}</CardTitle>
            <CardText>{t('detailed_booking_page.location_options.copy_text')}</CardText>
          </CardLocationContent>
        </CardLocationActionArea>
      </CardLocation>
    </Box>
  );
};

const MoveInMoveOutContent = ({ property, moveInText, moveOutDate }) => {
  return (
    <Box padding='1.5rem' gap='0.5rem'>
      <CustomBox>
        <SvgIcon name="login" size="24" />
        <SubtitleText>
          {t('detailed_booking_page.movein_moveout_options.move_in')}
          <br />
          {t('detailed_booking_page.movein_moveout_options.move_in_subtitle')}
        </SubtitleText>
      </CustomBox>
      <DescriptionText>
        {t('detailed_booking_page.movein_moveout_options.move_in_hour') + moveInText}
        <br />
        <br />
        {t('detailed_booking_page.movein_moveout_options.move_in_description')}
      </DescriptionText>
      <InnerDivider />
      <CustomBox>
        <SvgIcon name="logout_fi" size="24" />
        <SubtitleText>
          {t('detailed_booking_page.movein_moveout_options.move_out')}
        </SubtitleText>
      </CustomBox>
      <DescriptionText>
        {t('detailed_booking_page.movein_moveout_options.move_out_hour') + moveOutDate}
        <br />
        <br />
        {t('detailed_booking_page.movein_moveout_options.move_out_description')}
      </DescriptionText>
      {property.parking_text !== '' && (
        <>
          <InnerDivider />
          <CustomBox>
            <SvgIcon name="car_front_view" size="24" />
            <SubtitleText>
              {t('detailed_booking_page.movein_moveout_options.parking')}
            </SubtitleText>
          </CustomBox>
          <DescriptionText>
            {property.parking_text}
          </DescriptionText>
        </>
      )}
      <ContactDrawerFooter />
    </Box>
  );
};

const FacilitiesContent = () => {
  return (
    <Box padding='1.5rem' gap='0.5rem'>
      <CustomBox>
        <SvgIcon name="tv" size="24" />
        <SubtitleText>
          {t('detailed_booking_page.facilities_options.tv')}
        </SubtitleText>
      </CustomBox>
      <DescriptionText>
        {t('detailed_booking_page.facilities_options.tv_description')}
      </DescriptionText>
      <InnerDivider />
      <CustomBox>
        <SvgIcon name="property_microwave" size="24" />
        <SubtitleText>
          {t('detailed_booking_page.facilities_options.cooktop')}
        </SubtitleText>
      </CustomBox>
      <DescriptionText>
        {t('detailed_booking_page.facilities_options.cooktop_description.text_p1')}
        <br />
        {t('detailed_booking_page.facilities_options.cooktop_description.text_p2')}
        <br />
        {t('detailed_booking_page.facilities_options.cooktop_description.text_p3')}
        <br />
        {t('detailed_booking_page.facilities_options.cooktop_description.text_p4')}
      </DescriptionText>
      <InnerDivider />
      <CustomBox>
        <SvgIcon name="brush_line" size="24" />
        <SubtitleText>
          {t('detailed_booking_page.facilities_options.cleaning_service')}
        </SubtitleText>
      </CustomBox>
      <DescriptionText>
        {t('detailed_booking_page.facilities_options.cleaning_service_description')}
      </DescriptionText>
      <br />
      <ContactDrawerFooter />
    </Box>
  );
};

const CommonAreaContent = ({ features }) => {
  return (
    <Box padding='1.5rem' gap='0.5rem'>
      {features.gym && (
        <>
          <CustomBox>
            <SvgIcon name="gym" size="24" />
            <SubtitleText>
              {t('detailed_booking_page.common_area_options.gym')}
            </SubtitleText>
          </CustomBox>
          <DescriptionText>
            {t('detailed_booking_page.common_area_options.gym_text')}
          </DescriptionText>
          <InnerDivider />
        </>
      )}
      {features.pool && (
        <>
          <CustomBox>
            <SvgIcon name="spa_pool" size="24" />
            <SubtitleText>
              {t('detailed_booking_page.common_area_options.spa_pool')}
            </SubtitleText>
          </CustomBox>
          <DescriptionText>
            {t('detailed_booking_page.common_area_options.spa_pool_text')}
          </DescriptionText>
          <InnerDivider />
        </>
      )}
      {features.sky_lounge && (
        <>
          <CustomBox>
            <SvgIcon name="rooftop" size="24" />
            <SubtitleText>
              {t('detailed_booking_page.common_area_options.sky_lounge')}
            </SubtitleText>
          </CustomBox>
          <DescriptionText>
            {t('detailed_booking_page.common_area_options.sky_lounge_text')}
          </DescriptionText>
          <InnerDivider />
        </>
      )}
      <br />
      <ContactDrawerFooter />
    </Box>
  );
};

const ContactDrawerFooter = () => {
  return (
    <>
      <Typography fontSize="0.875rem" lineHeight="21px" fontWeight="500" padding="1.25rem 0">
        {t('detailed_booking_page.contact')}
      </Typography>
      <PaperLink href={`${t('services_page.whatsapp_extend')}`} target="_blank" rel="nofollow">
        <Division paddingY="0.75rem">
          <Typography display="flex" gap="0.5rem">
            <SvgIcon name="whatsapp_alt" size="18" />
            {t('dictionary.main_whatsup_formatted')}
          </Typography>
          <InfoChip size="small" label={`${t('detailed_booking_page.open_whatsapp')}`} />
        </Division>
      </PaperLink>
      <OuterDivider />
      <PaperLink href={`tel:${t('contact.corporate_phone')}`}>
        <Division paddingY="0.75rem">
          <Typography display="flex" gap="0.5rem">
            <SvgIcon name="phone_dark" size="18" />
            {t('dictionary.main_phone_formatted')}
          </Typography>
          <InfoChip size="small" label={`${t('detailed_booking_page.call_now')}`} />
        </Division>
      </PaperLink>
      <OuterDivider />
      <PaperLink href={`mailto:${t('contact.hello_email')}`}>
        <Division paddingY="0.75rem">
          <Typography display="flex" gap="0.5rem">
            <SvgIcon name="comment" size="18" />
            <CopyableText onClick={(e) => e.stopPropagation()}>{t('contact.hello_email')}</CopyableText>
          </Typography>
          <InfoChip size="small" label={`${t('detailed_booking_page.mail_now')}`} />
        </Division>
      </PaperLink>
    </>
  );
};

export default ReservationDetails;
