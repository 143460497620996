import styled from 'styled-components';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Link,
  SvgIcon,
  Paper,
  Typography
} from '../elements';

export const Container = styled(Box).attrs({
  padding: '0 1rem',
  maxWidth: '1000px',
  margin: 'auto'
})``;

export const CustomBox = styled(Box).attrs({
  display: 'flex',
  gap: '0.5rem'
})``;

export const ArrowRight = styled(SvgIcon).attrs({
  name: 'arrow_right_black',
  opacity: 0.7,
  width: '14px',
  height: '14px',
  margin: 'auto 0'
})``;

export const PaperContainer = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '1rem',
  width: 'auto'
})``;

export const PaperTop = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px 4px 0 0;
  padding: 1rem;
  width: 100%;
`;

export const PaperBottom = styled(Paper)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  background-color: ${({ theme }) => theme.palette.background[100]};
  border-radius: 0 0 4px 4px;
  padding: 3px 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
`;

export const PaperLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const DivisionsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: 100%;
`;

export const Division = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 0',
  margin: 0
})`
  cursor: pointer;
`;

export const OuterDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.primary[10]};
`;

export const InnerDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.primary[10]};
  margin: 1rem 0;
`;

export const TextBottom = styled(Typography).attrs({
  color: 'primary.50',
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.125rem'
})``;

export const TextTop = styled(Typography).attrs({
  color: 'primary.100',
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: '1.5rem'
})``;

export const DrawerContent = styled(Box).attrs({
  color: 'secondary.main',
  display: 'flex',
  justifyContent: 'center',
  gap: { xs: '10vw', md: '1.5rem' },
  padding: '0 0.5rem',
  position: 'relative',
  bottom: '1rem'
})``;

export const DrawerContentText = styled(Box).attrs({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: { xs: '0.5rem', sm: '5vw' }
})``;

export const DrawerContentTextTop = styled(Typography).attrs({
  fontWeight: '600',
  fontSize: '1.25rem',
  lineHeight: '1.875rem'
})``;

export const DrawerContentTextBottom = styled(Typography).attrs({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem'
})``;

export const DrawerTitle = styled(Typography).attrs({
  fontWeight: '600',
  fontSize: '1.125rem',
  lineHeight: '27px'
})``;

export const DescriptionText = styled(Typography).attrs({
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.125rem',
  paddingTop: '0.5rem'
})``;

export const SubtitleText = styled(Typography).attrs({
  variant: 'h6',
  lineHeight: '1.5rem'
})``;

export const CardLocationActionArea = styled(CardActionArea)`
  padding: 1rem;
  display: flex;
`;

export const CardLocationContent = styled(CardContent)`
  flex: 1;
  padding: 0 0 0 1rem;
`;

export const CardLocation = styled(Card).attrs({
  variant: 'outlined'
})`
  margin-top: 0.5rem;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
`;

export const CardLocationMedia = styled(CardMedia)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
`;

export const CardTitle = styled(Typography).attrs({
  color: 'primary',
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem'
})``;

export const CardText = styled(Typography).attrs({
  color: 'primary.60',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem'
})``;

export const InfoChip = styled(Chip)`
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.secondary.light};
  pointer-events: none;
`;

export const CopyableText = styled(Typography).attrs({
  component: 'span'
})`
  user-select: all;
  cursor: text;
`;
