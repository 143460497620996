import styled from 'styled-components';
import { Box, Button, Typography } from '../elements';

export const DrawerWrapper = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
  }
`;

export const DialogWrapper = styled(Box)`
  display: flex;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const CancelButton = styled(Button).attrs({
  size: 'large',
  variant: 'outlined'
})`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const ConfirmButton = styled(Button).attrs({
  size: 'large'
})`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const DatesWrapper = styled(Box)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const DatesTitle = styled(Typography)`
  font-weight: bold;
`;
