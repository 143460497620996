import { useEffect, useState } from 'react';

const GoogleMapMarker = (mapMarkerOptions) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(mapMarkerOptions);
    }
  }, [marker, mapMarkerOptions]);

  return null;
};

export default GoogleMapMarker;
