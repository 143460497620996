import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import inputValidator from '../../utils/inputValidator';
import {
  Box,
  Button,
  TextField,
  PhoneInput,
  SvgIcon,
  FormControl,
  FormControlLabel,
  Checkbox,
  Link
} from '../../UI/elements';
import { Typography } from '@mui/material';

const INITIAL_VALUES = {
  phone: '',
  company: '',
  full_name: '',
  work_email: '',
  agreement: false
};

const CorporateForm = ({ siteKey, url, onCancel, clickSource = '' }) => {
  const formRef = useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [corporateForm, setCorporateForm] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);

  const formValidations = [
    ['company', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['full_name', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phone', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['phone', { validation: 'phone', errorMessage: t('form_errors.phone_invalid') }],
    ['work_email', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['work_email', { validation: 'email', errorMessage: t('form_errors.email_invalid') }],
    ['agreement', { validation: 'required', errorMessage: t('form_errors.agreement_checkbox_not_checked') }]
  ];

  const handleChange = (inputName) => ({ target: { type, value, checked } }) => {
    const inputValue = type === 'checkbox' ? checked : value;
    const validate = {
      [inputName]: inputValue
    };
    const errors = inputValidator(validate, formValidations.filter(el => el[0] === inputName));

    setCorporateForm(state => ({
      ...state,
      [inputName]: inputValue
    }));
    setFieldErrors(errors);
  };

  const getErrorMessage = (inputName) => {
    return fieldErrors.filter(el => el.field === inputName)[0]?.error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = inputValidator(corporateForm, formValidations);

    if (errors.length) {
      setFieldErrors(errors);
      return;
    }

    const data = new FormData(formRef.current);
    submitForm(data);
  };

  const submitForm = async data => {
    setLoading(true);

    await axios.post(url, data)
      .then(() => {
        setShowSuccess(true);
        setCorporateForm(INITIAL_VALUES);
      })
      .catch(err => {
        const errMessages = err?.response?.data?.error;
        if (errMessages?.length) { setErrorMessage(errMessages[0]) }
      });

    setLoading(false);
  };

  if (showSuccess) {
    return (
      <Box
        className={`corporate-${clickSource}modal-tym`}
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: ['auto', 1],
          maxWidth: { xs: 1, md: 360 },
          textAlign: 'center'
        }}
      >
        <SvgIcon
          className={`corporate-${clickSource}modal-tym-img`}
          name="success_large"
          size={100}
        />
        <Typography sx={{ m: 2 }} variant="h5">
          {t('companies_page.contact_form.success_message')}
        </Typography>
        <Button
          href="/"
          variant="contained"
          size="large"
          sx={{ mt: 1 }}
        >
          {t('companies_page.contact_form.success_button')}
        </Button>
      </Box>
    );
  }

  return (
    <ThemeProvider>
      <Box
        className={`corporate-${clickSource}modal`}
        px={1}
        ref={formRef}
        component='form'
        data-type="json"
        id="company-contact-form"
        onSubmit={handleSubmit}
        remote
      >
        <TextField
          className={`corporate-${clickSource}modal-full-name`}
          error={!!getErrorMessage('full_name')}
          fullWidth
          helperText={getErrorMessage('full_name')}
          htmlFor='full-name'
          id='full-name'
          label={t('companies_page.contact_form.fields.full_name')}
          name='company_contact[full_name]'
          onChange={handleChange('full_name')}
          required
          sx={{ my: 0.75 }}
          value={corporateForm.full_name}
        />
        <PhoneInput
          className={`corporate-${clickSource}modal-phone`}
          error={!!getErrorMessage('phone')}
          fullWidth
          helperText={getErrorMessage('phone')}
          htmlFor='company_contact_phone'
          id='company_contact_phone'
          label={t('companies_page.contact_form.fields.phone')}
          name='company_contact[phone]'
          onChange={(value) => handleChange('phone')({ target: { value } })}
          required
          sx={{ my: 0.75 }}
          autoFormat={false}
          value={corporateForm.phone}
        />
        <Box className="invalid-phone-number d-none text-tabas-dark mt-1 fs-8">
          {t('form_errors.phone_invalid')}
        </Box>
        <TextField
          className={`corporate-${clickSource}modal-email`}
          error={!!getErrorMessage('work_email')}
          fullWidth
          label={t('companies_page.contact_form.fields.work_email')}
          id='work-email'
          helperText={getErrorMessage('work_email')}
          htmlFor='work-email'
          name='company_contact[work_email]'
          onChange={handleChange('work_email')}
          sx={{ my: 0.75 }}
          required
          type='email'
          value={corporateForm.work_email}
        />
        <TextField
          className={`corporate-${clickSource}modal-company`}
          autoComplete='name'
          error={!!getErrorMessage('company')}
          fullWidth
          helperText={getErrorMessage('company')}
          htmlFor='company'
          id='company'
          label={t('companies_page.contact_form.fields.company')}
          name='company_contact[company]'
          onChange={handleChange('company')}
          required
          sx={{ my: 0.75 }}
          value={corporateForm.company}
        />
        <TextField
          className={`corporate-${clickSource}modal-remarks`}
          label={t('companies_page.contact_form.fields.remarks')}
          name='company_contact[remarks]'
          fullWidth
          multiline
          rows={3}
          sx={{ my: 0.75 }}
        />
        <FormControl
          error={!!getErrorMessage('agreement')}
          sx={{ pb: 1 }}
          formHelperText={getErrorMessage('agreement')}
        >
          <FormControlLabel
            className={`corporate-${clickSource}modal-check-terms`}
            control={
              <Checkbox
                name="agreement"
                value={corporateForm.agreement}
                onChange={handleChange('agreement')}
              />
            }
            label={(
              <Typography
                alignSelf="center"
                fontSize="0.75rem"
                color="primary.70"
              >
                {t('dictionary.policies_agreement_text')}
                <Link href={t('privacy_policy_path')} target="_blank" mx={0.25}>
                  {t('dictionary.policies_agreement_link')}
                </Link>
              </Typography>
            )}
          />
        </FormControl>
        {errorMessage && (
          <Box className="form-feedback mb-0">
            <Box
              className="alert alert-danger rounded-10 feedback-error"
              sx={{ padding: 10, fontSize: 14 }}
            >
              {errorMessage}
            </Box>
          </Box>
        )}
        <Box sx={{ my: 0.75 }}>
          <ReCAPTCHA sitekey={siteKey} />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1.5,
          py: 1,
          display: 'flex',
          bgcolor: ['secondary.light', 'secondary.light', 'secondary.main'],
          justifyContent: ['space-around', 'space-around', 'flex-end']
        }}
      >
        {onCancel && (
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => onCancel()}
            sx={{ mr: [0, 0, 2] }}
          >
            {t('companies_page.contact_form.cancel')}
          </Button>
        )}
        <Button
          className={`corporate-${clickSource}modal-get-in-touch`}
          id="form-submit"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        >
          {t('companies_page.contact_form.submit')}
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default CorporateForm;
