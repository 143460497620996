import React from 'react';
import styled, { css } from 'styled-components';
import { Typography as MuiTypography } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';

const Typography = ({ children, withShadow, textTransform, ...rest }) => {
  return (
    <ThemeProvider>
      <StyledTypography
        $textTransform={textTransform}
        $shadow={withShadow}
        {...rest}
      >
        {children}
      </StyledTypography>
    </ThemeProvider>
  );
};

const StyledTypography = styled(MuiTypography)`
  ${({ thickness }) => css`
    && {
      font-weight: ${thickness};
    }
  `}
  ${({ $textTransform }) => css`
    text-transform: ${$textTransform};
  `}
  ${({ $shadow }) => $shadow && css`
    text-shadow: 0px 2px 10px rgba(41, 45, 50, 0.25);
  `}
`;

export default Typography;
