import React from 'react';
import styled, { css } from 'styled-components';
import {
  Dialog,
  SvgIcon,
  Divider,
  List,
  ListItem,
  Link,
  Typography,
  Box
} from '../elements';
import { DropdownMenu } from '.';
import { t } from '../../../js/common/translations';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { specialCharacterRenderer } from '../../utils/specialCharacterRenderer';

const MobileDialogMenu = ({
  open,
  onClose,
  links,
  currentMember,
  currentMemberIsAdmin,
  casatb
}) => {
  const breakpoint = useBreakpoint();

  const contactLinks = [
    {
      icon_props: { name: 'whatsapp', size: 18 },
      label: t('navigation.support.whatsapp'),
      description: t('navigation.support.whatsapp_contact_formatted'),
      target: '_blank',
      href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.support')}`
    },
    {
      icon_props: { name: 'phone', size: 18 },
      label: t('navigation.support.phone'),
      href: t('navigation.support.contact_phone'),
      description: t('navigation.support.contact_phone_formatted')
    },
    {
      icon_props: { name: 'envelope', size: 18 },
      label: t('navigation.support.email'),
      href: t('navigation.support.email_send'),
      description: t('contact.hello_email')
    }
  ];

  const locales = [
    {
      href: t('navigation.locale_en'),
      description: 'ENGLISH'
    },
    {
      href: t('navigation.locale_pt'),
      description: 'PORTUGUÊS'
    }
  ];

  const termsAndPoliciesLinks = [
    {
      href: t('mobile_menu.cookies_policy_path'),
      description: t('mobile_menu.cookies_policy')
    },
    {
      href: t('mobile_menu.terms_and_conditions_path'),
      description: specialCharacterRenderer(t('mobile_menu.terms_and_conditions'))
    },
    {
      href: t('mobile_menu.privacy_policy_path'),
      description: t('mobile_menu.privacy_policy')
    }
  ];

  const memberLinks = {
    login_path: [{
      href: t('navigation.new_session_path'),
      description: t('navigation.login_link')
    }],
    logged_member: [
      {
        href: t('logged_user_navigation.bookings_path'),
        description: t('logged_user_navigation.bookings').toUpperCase()
      },
      {
        href: t('logged_user_navigation.invites_path'),
        description: t('logged_user_navigation.invites').toUpperCase()
      },
      {
        href: t('logged_user_navigation.profile_link_path'),
        description: currentMember?.fullname.toUpperCase()
      }
    ],
    admin_member: [{
      href: t('logged_user_navigation.backoffice_path'),
      description: 'BACKOFFICE'
    }]
  };

  return (
    <Dialog
      id="mobile-dialog-menu"
      open={['xs', 'sm'].includes(breakpoint) && open}
      onClose={onClose}
      fullScreen={true}
      scroll="paper"
      headerContent={<Logo />}
      headerProps={{
        sx: {
          borderBottom: '1px solid rgba(34, 34, 34, 0.1)',
          padding: '16px 0',
          margin: '0 24px'
        }
      }}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: '0 !important'
        }
      }}
    >
      <Box sx={{ mt: 1.5 }}>
        <LinkList links={links} />
        {currentMemberIsAdmin && (<LinkList links={memberLinks.admin_member} />)}
        {(currentMember && !casatb) ? (
          <LinkList links={memberLinks.logged_member} />
        ) : (!casatb && <LinkList links={memberLinks.login_path} />
        )}
        <MenuSectionTitle>
          {t('mobile_menu.contact_us')}
        </MenuSectionTitle>
        <LinkList links={contactLinks} />
        <MenuSectionTitle>
          {t('mobile_menu.language')}
        </MenuSectionTitle>
        <DropdownMenu
          anchorEl={LanguageSelector}
          anchorElProps={{
            children: (
              <>
                <Typography variant="subtitle1">
                  <LinkIcon name="globe" size="18" />
                  {t('mobile_menu.current_language')}
                </Typography>
                <SvgIcon name="mini_arrow_right" />
              </>
            )
          }}
          menuProps={{
            fullWidth: true
          }}
          menuItems={locales}
        />
        <SocialLinksWrapper>
          <a href="https://www.linkedin.com/company/tabas/" className='casatb-hide' target="_blank" rel="noreferrer nofollow">
            <SvgIcon name="linkedin_with_frame" size="40" />
          </a>
          <a href={`https://www.instagram.com/${casatb ? 'casatb_' : 'tabashomes'}/`} target="_blank" rel="noreferrer nofollow">
            <SvgIcon name="instagram_with_frame" size="40" />
          </a>
          <a href="https://www.facebook.com/tabashomes" className='casatb-hide' target="_blank" rel="noreferrer nofollow">
            <SvgIcon name="fb_with_frame" size="40" />
          </a>
        </SocialLinksWrapper>
        <MenuDivider />
        <LinkList sx={{ my: '2.5rem', py: 0 }} links={termsAndPoliciesLinks} />
        <Typography
          variant="caption"
          component="p"
          align="center"
        >
          {t('mobile_menu.copyright')}
        </Typography>
      </Box>
    </Dialog>
  );
};

const LinkList = ({ links, ...rest }) => {
  return (
    <List sx={{ py: 0 }} {...rest}>
      {links.map(({ href, icon_props: iconProps, description, casatb }, i) => {
        if (casatb) {
          return;
        }
        return (
          <span key={i}>
            <ListItem
              component={Link}
              className={`${href.includes('/whatsapp_redirect') && 'whatsapp-link whatsapp-link-menu'}`}
              href={href}
              sx={{ px: 0 }}
            >
              <Typography variant="subtitle1" component="span">
                {iconProps && (
                  <LinkIcon {...iconProps} />
                )}
                {description}
              </Typography>
            </ListItem>
            <MenuDivider $withGutters />
          </span>
        );
      }
      )}
    </List>
  );
};

const Logo = styled(SvgIcon).attrs({
  name: 'logo_default',
  size: 105
})`
  display: flex;
  align-self: center;
`;

const MenuSectionTitle = styled(Typography).attrs({
  thickness: 500,
  variant: 'body2'
})`
  color: ${({ theme }) => theme.palette.primary.light};
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
`;

const MenuDivider = styled(Divider)`
  color: #222222;
  opacity: 0.1;
  ${({ $withGutters }) => $withGutters && css`
    margin: 0.25rem 0;
  `}
`;

const LinkIcon = styled(SvgIcon)`
  margin-right: 0.5rem;
`;

const LanguageSelector = styled(Link).attrs({
  underline: 'none'
})`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  padding: 0.5rem 0;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 2.5rem 0;
  a {
    margin: 0.5rem;
  }
`;

export default MobileDialogMenu;
