import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Menu,
  ListItem,
  Link,
  Divider,
  Typography,
  Box
} from '../elements';

const DropdownMenu = ({
  anchorEl: AnchorElement,
  anchorElProps,
  menuItems,
  menuItemRender,
  menuProps = {},
  menuItemProps,
  variant,
  children
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AnchorElement
        onClick={handleClick}
        {...anchorElProps}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {variant === 'home' ? (
          <HomeContainer>
            {children}
          </HomeContainer>
        ) : (
          <Box>
            {menuItems.map((item, index) => {
              const { description, onClick, iconEl, highlight, ...rest } = item;
              const componentEl = item.href ? Link : undefined;
              const showDivider = menuItemProps?.divider || item.divider;

              const handleItemClick = (e, item) => {
                handleClose();
                onClick && onClick(e, item);
              };

              return (
                menuItemRender
                  ? (
                    <span key={index}>
                      {showDivider && index !== 0 && (
                        <MenuItemDivider />
                      )}
                      {menuItemRender(item, {
                        key: index,
                        ...rest,
                        onClick: (e) => handleItemClick(e, item)
                      })}
                    </span>
                  ) : (
                    <span key={index}>
                      {showDivider && index !== 0 && (
                        <MenuItemDivider />
                      )}
                      <MenuItem
                        key={index}
                        button
                        {...rest}
                        component={componentEl}
                        onClick={(e) => handleItemClick(e, item)}
                        {...menuItemProps}
                      >
                        {iconEl && (
                          <Box mr={0.5}>
                            {iconEl}
                          </Box>
                        )}
                        <MenuItemLabel $highlight={highlight}>
                          {item.description}
                        </MenuItemLabel>
                      </MenuItem>
                    </span>
                  )
              );
            })}
          </Box>
        )}
      </Menu>
    </>
  );
};

const HomeContainer = styled(Box)`
  max-width: 640px;
  display: grid;
  grid-template-areas:
    "sao-paulo rio-de-janeiro"
    "sao-paulo brasilia";
`;

const MenuItemDivider = styled(Divider).attrs({
  component: 'li'
})`
  margin: 8px 16px;
`;

const MenuItemLabel = styled(Typography)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.primary.light};
  ${({ $highlight, theme }) => $highlight && css`
    font-size: 1rem;
    color: ${theme.palette.primary.main};
    font-weight: 600;
  `}
`;

const MenuItem = styled(ListItem)`
  border: none;
  min-width: 120px;
  transition: min-width 300ms ease-in-out;
`;

export default DropdownMenu;
