import React from 'react';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { ThemeProvider } from '../../contexts/theme';
import { CustomTimeline } from './Timeline.styles';
import { Box, SvgIcon } from '../elements';
import { t } from '../../../js/common/translations';

const Timeline = ({ timelineItem, ...rest }) => {
  return (
    <ThemeProvider>
      <CustomTimeline>
        {timelineItem.map(({ content, iconName, alt }, index) => {
          return (
            <TimelineItem position={index % 2 ? 'right' : 'left'} key={index}>
              <TimelineSeparator>
                <TimelineDot sx={{ width: 42, height: 42, p: 0, my: 2 }}>
                  <SvgIcon alt={`${t('dictionary.alt_img')}${alt}`} name={iconName} />
                </TimelineDot>
                {(timelineItem.length - 1) !== index && <TimelineConnector sx={{ height: 150, my: '-2rem' }} />}
              </TimelineSeparator>
              <TimelineContent>
                <Box sx={{ float: index % 2 ? 'left' : 'right' }} mx={1} maxWidth={400}>
                  {content}
                </Box>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </CustomTimeline>
    </ThemeProvider>
  );
};

export default Timeline;
