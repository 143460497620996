import React from 'react';
import styled from 'styled-components';
import { Box, SvgIcon } from '../elements';

export const FeedbackContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const QuoteDecorator = styled(SvgIcon).attrs({
  name: 'quotation_marks_light',
  size: 100
})``;

export const FeedbackQuoteDecoration = styled(Box).attrs({
  children: <QuoteDecorator />
})`
  min-width: 100px;
  display: flex;
  align-items: center;
  margin: -20px 10px 0 10px;
  transform: ${({ $endQuote }) => $endQuote ? 'rotate(180deg)' : 'none'};
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;
