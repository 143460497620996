import React, { useState } from 'react';
import Swiper from './Swiper';
import { Box, SvgIcon } from '../elements';
import PropertyCard from './PropertyCard';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { ControlLeft, ControlRight } from './PropertyCardSlider.styles';

const PropertyCardSlider = ({
  properties,
  withBody = true,
  seasonalLink = false,
  season = 'none',
  customPrices = {},
  customOriginalPrices = {},
  casatb
}) => {
  const currentBreakpoint = useBreakpoint();
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const perView = { xs: 1, sm: 2, md: 2, lg: 3, xl: 3 };

  const SliderControls = () => {
    const isLastSlide = properties.length === activeIndex + perView[currentBreakpoint];
    return (
      <>
        <ControlLeft
          disabled={activeIndex === 0}
          onClick={() => swiper.slidePrev()}
        >
          <SvgIcon name="arrow_left_black" size="16" />
        </ControlLeft>
        <ControlRight
          disabled={isLastSlide || properties.length <= perView[currentBreakpoint]}
          onClick={() => swiper.slideNext()}
        >
          <SvgIcon name="arrow_right_black" size="16" />
        </ControlRight>
      </>
    );
  };

  return (
    <Box sx={{ px: [0, 0, 4], position: 'relative' }}>
      <SliderControls />
      <Swiper
        slides={properties.map(property => (
          <Box
            key={property.id}
            sx={{
              pt: 2,
              pb: 3.5,
              width: 1,
              flexFlow: 'column',
              display: 'flex',
              height: 1
            }}>
            <PropertyCard
              casatb={casatb}
              property={property}
              withBody={withBody}
              seasonalLink={seasonalLink}
              season={season}
              availableOnRequest={property.availability_on_request}
              customPrice={customPrices[property.internal_id]}
              customOriginalPrice={customOriginalPrices[property.internal_id]}
            />
          </Box>
        ))}
        loop={false}
        slidesPerView={perView[currentBreakpoint]}
        spaceBetween={16}
        onSwiper={setSwiper}
        onSlideChange={({ activeIndex }) => setActiveIndex(activeIndex)}
      />
    </Box>
  );
};

export default PropertyCardSlider;
