import styled from 'styled-components';
import { Box, Link } from '../elements';

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalLink = styled(Link)`
  text-decoration: none;
  font-weight: ${({ casatb }) => casatb && '500'};
  font-size: ${({ casatb }) => casatb && '14px'};
  font-family: ${({ casatb }) => casatb && 'Poppins'};
  color: ${({ casatb }) => casatb && '#606F7D'};
`;
