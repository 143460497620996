import React from 'react';
import { t } from '../../../js/common/translations';
import { Dialog, Divider, SvgIcon, Typography } from '../elements';
import { ContentWrapper, ModalLink } from './ContactUsDialog.styles';

const ContactUsDialog = ({
  onClose,
  open,
  props,
  maxWidth,
  whatsappContactLink,
  casatb,
  ...rest
}) => {
  return (
    <Dialog
      casatb={casatb}
      maxWidth={maxWidth}
      onClose={onClose}
      sx={{ width: '100%' }}
      open={open}
      headerContent={(
        <ContentWrapper pl={5} pr={casatb ? 3.5 : 4 } pt={casatb ? 0.5 : 0} my={0} gap={0}>
          <SvgIcon mb={casatb && 1} name={casatb ? 'casatb_default_logo' : 'logo_default'} size={casatb ? 102 : 184} />
          <Typography sx={{ fontWeight: casatb && 400 }} my={0.25} color="primary.70" variant="lead">{t('footer.slogan')}</Typography>
        </ContentWrapper>
      )}
      {...rest}
    >
      <Divider />
      <ContentWrapper gap={1} my={1} >
        <Typography sx={{ fontSize: casatb && '14px', fontWeight: casatb && '500' }}>{t('footer.contact_us')}</Typography>
        <ModalLink
          href={props.whatsappRedirectPathHref}
          target="_blank"
          casatb={casatb}
          rel="nofollow"
          className="whatsapp-link whatsapp-link-content-contact-us"
        >
          <SvgIcon mr={0.5} name={casatb ? 'whatsapp_gray' : 'whatsapp'} />
          {t('contact.whatsapp_formatted')}
        </ModalLink>
        <ModalLink casatb={casatb} href={`tel: ${t('contact.phone')}`}>
          <SvgIcon mr={0.5} name={casatb ? 'phone_gray' : 'phone_dark'} />
          {t('contact.phone_formatted')}
        </ModalLink>
        <ModalLink casatb={casatb} href={`mailto: ${t('contact.booking_email')}`}>{t('contact.booking_email')}</ModalLink>
      </ContentWrapper>
      {!casatb && <>
        <Divider />
        <ContentWrapper gap={1} my={1}>
          <Typography>{t('footer.for_corporate')}</Typography>
          <ModalLink href={`mailto: ${t('contact.corporate_email')}`}>
            {t('contact.corporate_email')}
          </ModalLink>
          <ModalLink href={`tel: ${t('contact.corporate_phone')}`}>
            {t('contact.corporate_phone_formatted')}
          </ModalLink>
        </ContentWrapper>
      </>}
    </Dialog>
  );
};

export default ContactUsDialog;
