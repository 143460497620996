import styled from 'styled-components';
import { Card, CardActionArea as MuiCardActionArea, Divider, SvgIcon, Typography } from '../elements';

export const ArrowRight = styled(SvgIcon).attrs({
  name: 'arrow_right_black',
  margin: 'auto 0',
  size: 16
})`
  opacity: 0.7;
`;

export const CalendarIcon = styled(SvgIcon).attrs({
  name: 'calendar_check_line',
  size: 16
})`
  opacity: 0.7;
`;

export const CardActionArea = styled(MuiCardActionArea)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  &:hover {
    color: inherit;
  }
`;

export const CardStyled = styled(Card).attrs({
  variant: 'outlined'
})`
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 3px;
  width: 100%;
`;

export const DateText = styled(Typography).attrs({
  variant: 'lead',
  fontSize: 12,
  lineHeight: '18px'
})`
  ${({ $mobile }) => $mobile && `
    margin-top: 0.25rem;
  `};
  opacity: 0.7;
`;

export const DividerStyled = styled(Divider)`
  margin: 1rem 0;
  width: 100%;
  border-color: ${({ theme }) => theme.palette.primary[10]};
  opacity: 0.5;
`;

export const CardBody = styled(Typography).attrs({
  color: 'primary.100',
  variant: 'h6'
})``;

export const CardBottom = styled(Typography).attrs({
  color: 'primary.60',
  fontSize: '0.625rem',
  fontWeight: 500,
  lineHeight: '15px'
})``;

export const CardTitle = styled(Typography).attrs({
  color: 'primary.70',
  fontSize: '0.625rem',
  fontWeight: 400,
  lineHeight: '15px'
})``;
