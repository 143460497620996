import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const SwiperPagination = styled.div.attrs({
  className: 'swiper-pagination'
})`
  width: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  transition: .3s all ease;
  transform: translate3d(0,0,0);
  z-index: 10;
  bottom: 0;
`;

export const SwiperContainer = styled(Swiper)`
  height: 100%;

  ${({ pagination, $styles }) => pagination?.el && css`
    ${SwiperPagination}${pagination.el} {
      ${$styles?.pagination && $styles.pagination}

      & > .swiper-pagination-bullet {
        ${({ $styles }) => $styles.bullet || css`
          width: 12px;
          height: 12px;
          margin: 0 8px !important;
          border: 2px solid ${({ theme }) => theme.palette.secondary.dark} !important;
          background-color: ${({ theme }) => theme.palette.primary.dark} !important;
          opacity: 1;
          cursor: pointer;
        `}
      }
      & > .swiper-pagination-bullet-active {
        ${({ $styles }) => $styles.activeBullet || css`
          border: 2px solid ${({ theme }) => theme.palette.primary.dark} !important;
          background-color: ${({ theme }) => theme.palette.secondary.dark} !important;
        `}
      }
    }
  `}

  .swiper-slide {
    height: auto !important;
  }

  ${({ $styles }) => $styles.container && css`
    && {
      ${$styles.container}
    }
  `}
`;
