import styled from 'styled-components';
import { Menu as MuiMenu } from '@mui/material';

const Menu = styled(MuiMenu)`
  .MuiMenu-paper {
    box-shadow: 0px 4px 8px rgba(41, 45, 50, 0.16);
    border-radius: 8px;
  }
`;

export default Menu;
