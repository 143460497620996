import React from 'react';
import { Box, Typography } from '../elements';

const ContentWithTitle = (props) => (
  <Box sx={{ pt: 2.5 }}>
    <Typography
      variant="h2"
      color="primary.main"
      sx={{ fontWeight: 700, fontSize: '1.5rem', fontFamily: 'Cambon' }}
    >
      {props.subtitle}
    </Typography>
    <Box sx={{ pt: 0.5 }}>
      {props.content.map((content, index) => (
        <Typography key={index} variant="body1" component="p" paragraph>{content}</Typography>
      ))}
      {props.children}
    </Box>
  </Box>
);

export default ContentWithTitle;
