import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale(document.documentElement.lang);

export const SIMPLE_DATE_FORMAT = 'YYYY-MM-DD'; // based on moment date format
export const DATEPICKER_FORMAT = document.documentElement.lang === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'; // Format for text form input

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
export const inclusions = {
  NONE: 'none',
  LEFT: 'left',
  RIGHT: 'right',
  BOTH: 'both'
};

const includeLeft = inclusion => inclusion === inclusions.LEFT || inclusion === inclusions.BOTH;
const includeRight = inclusion => inclusion === inclusions.RIGHT || inclusion === inclusions.BOTH;

const dateComparator = (a, b) => new Date(a).getTime() - new Date(b).getTime();

/**
 * Clones the date
 *
 * @param date
 * @returns {Date}
 */
export function clone(date) {
  return date ? new Date(date.getTime()) : date;
}

/**
 * Reset the time part of a date
 * @param {Date} date
 */
export function resetTime(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}

/**
 * Adds days to the given date, a negative value in days will decrement
 * @param {Date} date
 * @param {number} days
 */
export function addDays(date, days) {
  const newDate = clone(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

/**
 * Get the days between 2 dates
 * @param {Date} startDate
 * @param {Date} endDate
 * @param inclusion
 */
export function dayDatesBetween(startDate, endDate, inclusion = inclusions.LEFT) {
  let current = resetTime(addDays(startDate, includeLeft(inclusion) ? 0 : 1));
  const to = resetTime(addDays(endDate, includeRight(inclusion) ? 1 : 0));
  const result = [];
  while (current.getTime() < to.getTime()) {
    result.push(current);
    current = addDays(current, 1);
  }
  return result;
}

/**
 * Gets the different in terms of days
 *
 * @param first
 * @param second
 * @returns {number}
 */
export function daysDiff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / MILLISECONDS_IN_A_DAY);
}

/**
 * Gets the different in terms of natural days
 *
 * @param {Date} first
 * @param {Date} second
 * @returns {number}
 */
export function naturalDaysDiff(first, second) {
  const utc1 = Date.UTC(first.getFullYear(), first.getMonth(), first.getDate());
  const utc2 = Date.UTC(second.getFullYear(), second.getMonth(), second.getDate());

  return Math.floor((utc2 - utc1) / MILLISECONDS_IN_A_DAY);
}

/**
 * Gets the date of the first not available from the given one (sinceDate), looking into the unavailableDates
 *
 * @param sinceDate
 * @param unavailableDates
 * @returns {*}
 */
export function getFirstUnAvailableDateSince(sinceDate, unavailableDates) {
  if (unavailableDates.length === 0) {
    return null;
  }
  const sortedUnavailableDates = [...unavailableDates].sort(dateComparator);

  // TODO: improve with a binary find
  let i;
  for (i = 0; i < sortedUnavailableDates.length; i++) {
    if (naturalDaysDiff(sinceDate, sortedUnavailableDates[i]) >= 1) {
      break;
    }
  }

  if (i < sortedUnavailableDates.length) {
    return clone(sortedUnavailableDates[i]);
  }

  return null;
}

/**
 *
 * @param {string} str
 * @param {string} format
 * @returns {Date}
 */
export function parse(str, format = undefined) {
  const momentDate = moment(str, format);
  return momentDate.toDate();
}

/**
 *
 * @param {Date} date
 * @param {string} format
 * @returns {string}
 */
export function format(date, format = undefined) {
  const momentDate = moment(date);
  return momentDate.format(format);
}

export function verifyBlockedDates(blockedDates, checkIn) {
  if (blockedDates.length === 1) return null;

  const sortedUnavailableDates = [...blockedDates].sort(dateComparator);

  const nextMax = sortedUnavailableDates.find(date => new Date(date) > checkIn);

  if (nextMax) {
    return nextMax;
  }
  return null;
}

export function lastBlockedDate(blockedDates) {
  if (blockedDates.length === 1) return null;

  const sortedUnavailableDates = [...blockedDates].sort(dateComparator);

  return sortedUnavailableDates[sortedUnavailableDates.length - 1];
}
