import React from 'react';
import styled, { css } from 'styled-components';
import { TextField as MuiTextField } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';
import { SvgIcon } from '.';

const StyledTextField = styled(MuiTextField)`
  ${({ telinput }) => telinput && css`
    input {
      height: auto;
    }
  `}
  ${({ pill }) => pill && css`
    fieldset {
      border-radius: 50rem;
    }
  `}
  ${({ success }) => success && css`
    fieldset {
      border-color: ${({ theme }) => theme.palette.success.main};
    }
    & .MuiInputBase-root {
      &:hover fieldset {
        border-color: ${({ theme }) => theme.palette.success.dark};
      }
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.success.main};
      }
    }
  `}
  ${({ error }) => error && css`
    & .MuiInputBase-root {
      &:hover fieldset {
        border-color: ${({ theme }) => theme.palette.error.dark};
      }
    }
  `}
`;

const TextField = ({ InputLabelProps = {}, type, error, success, ...rest }) => {
  return (
    <ThemeProvider>
      <StyledTextField
        {...rest}
        error={error}
        success={success}
        type={type}
        InputLabelProps={{
          shrink: type === 'tel' || type === 'date' ? true : undefined,
          ...InputLabelProps
        }}
        FormHelperTextProps={{ sx: { mx: 0 } }}
        helperText={
          (error &&
            <>
              <SvgIcon name="error_input_icon" mr={0.375}/>
              {rest.helperText}
            </>
          ) || (success &&
            <>
              <SvgIcon name="success_input_icon" mr={0.375}/>
              {rest.helperText}
            </>
          )
        }
      >
      </StyledTextField>
    </ThemeProvider>
  );
};

export default TextField;
