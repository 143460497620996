import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Link as MuiLink } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';

const Link = forwardRef(({ noDecoration, ...rest }, ref) => {
  return (
    <ThemeProvider>
      <StyledLink ref={ref} $noDecoration={noDecoration} {...rest} />
    </ThemeProvider>
  );
});

const StyledLink = styled(MuiLink)`
  :hover {
    color: ${({ theme, color }) => theme.palette[color || 'primary'].dark};
    cursor: pointer;
  }
  ${({ $noDecoration }) => $noDecoration && css`
    text-decoration: none;
  `}
`;

export default Link;
