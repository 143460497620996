import { IconButton } from '../elements';
import styled, { css } from 'styled-components';

const controlCss = css`
  position: absolute;
  height: 42px;
  width: 42px;
  border: 1px solid rgba(41, 45, 50, 0.1);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.palette.background.default};
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
  ${({ disabled }) => disabled && css`
    display: none;
  `}
`;

export const ControlLeft = styled(IconButton)`
  ${controlCss}
  left: 0;
`;

export const ControlRight = styled(IconButton)`
  ${controlCss}
  right: 0;
`;
