import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Button, ListItem, Paper, SvgIcon, TextField, Typography } from '../elements';
import { InputAdornment } from '@mui/material';

export const SubOptionContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  & > span {
    flex: 0 0 50%;
  }
  & > span:first-child {
    flex: 1 0 100%;
  }
`;

export const MenuItem = styled(ListItem)`
  border: none;
  min-width: 120px;
  transition: min-width 300ms ease-in-out;
`;

export const MenuItemLabel = styled(Typography)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.primary.light};
  ${({ $highlight, theme }) => $highlight && css`
    font-size: 1rem;
    color: ${theme.palette.primary.main};
    font-weight: 600;
  `}
`;

export const SmallTitle = styled(Typography).attrs({
  color: 'primary.50',
  fontWeight: '400',
  fontSize: '0.75rem',
  lineHeight: '1.125rem'
})``;

export const BottomText = styled(Typography).attrs({
  color: 'primary.100',
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: '1.5rem'
})``;

export const SearchWhiteIcon = styled(SvgIcon).attrs({
  name: 'search_white'
})`
  margin-right: 4px;
`;

export const SearchIconAdornment = styled(InputAdornment).attrs({
  position: 'end',
  children: <SvgIcon name="search_circle" size="31" sx={{ backgroundColor: 'primary.main', borderRadius: '4px' }} />
})`
  margin-right: -8px;
`;

export const DropIconAdornment = styled(InputAdornment).attrs({
  position: 'end',
  children: <SvgIcon name="arrow_down" size="10" />
})`
  margin-top: -12px;
  opacity: 0.4;
`;

export const LargeSearchContainer = styled(Paper).attrs({
  elevation: 0
})`
  display: flex;
  width: 100%;
  height: 80px;
  max-width: 391px;
  align-items: center;
  padding: 12px;
`;

export const LargeTextField = styled(TextField).attrs({
  size: 'small'
})`
  margin-bottom: -12px;
  fieldset {
    display: none;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    top: -10px;
    transform: scale(1, 1.05);
    color: ${({ theme }) => theme.palette.primary.main};
  }
  * {
    cursor: pointer;
  }
  & .MuiOutlinedInput-input {
    padding-left: 0;
    font-size: 14px;
    padding-bottom: 0;
    padding-top: 14px;
    width: 80%;
  }

`;

export const SearchBtn = styled(Button).attrs({
  size: 'large',
  py: '0.5rem',
  sx: {
    '& .MuiTypography-root': {
      fontSize: '1rem'
    }
  }
})`
`;

export const SearchWrapper = styled(Box)`
  > div > div {
    cursor: pointer;
  }
`;
