import React from 'react';
import styled from 'styled-components';
import SvgIcon from './SvgIcon';
import { Box, Drawer as MuiDrawer, DialogTitle } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';

const DrawerDark = ({
  children,
  headerContent = '',
  headerContentProps,
  headerProps = {},
  contentProps = {},
  ...rest
}) => {
  const isWebview = window.isWebview;
  const sendMessage = () => window.ReactNativeWebView.postMessage('CloseButtonClicked');

  return (
    <ThemeProvider>
      <CustomMuiDrawer {...rest}>
        <DrawerWrapper>
          <DrawerHeader {...headerProps}>
            <HeaderContentWrapper {...headerContentProps}>{headerContent}</HeaderContentWrapper>
            <HeaderCloseIconWrapper>
              <SvgIcon
                name="close_icon_black"
                onClick={() => {
                  rest.onClose();
                  if (isWebview) {
                    sendMessage();
                  }
                }}
              />
            </HeaderCloseIconWrapper>
          </DrawerHeader>
          <DrawerContent {...contentProps}>{children}</DrawerContent>
        </DrawerWrapper>
      </CustomMuiDrawer>
    </ThemeProvider>
  );
};

const CustomMuiDrawer = styled(MuiDrawer)`
  height: 158px;
  .MuiBackdrop-root {
    background-color: rgba(41, 45, 50, 0.3);
  }
`;

const DrawerWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

const DrawerHeader = styled(DialogTitle)`
  display: flex;
  padding: 0;
`;

const DrawerContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderContentWrapper = styled(Box)`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const HeaderCloseIconWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  z-index: 100;
`;

export default DrawerDark;
