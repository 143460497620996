import React from 'react';
import styled from 'styled-components';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';

const Tooltip = styled(({ className, ...props }) => (
  < MuiTooltip {...props}
    arrow
    enterTouchDelay={100}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary['90']
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary['90']
  }
}));

export default Tooltip;
