import React from 'react';
import styled, { css } from 'styled-components';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import { ThemeProvider } from '../../contexts/theme';
import { SvgIcon } from '.';

const Button = React.forwardRef(({
  variant = 'contained',
  color = 'primary',
  startIcon = {},
  pill = false,
  border = false,
  loading,
  children,
  href,
  light,
  ...rest
}, ref) => {
  return (
    <ThemeProvider>
      <StyledButton
        startIcon={
          'name' in startIcon
            ? <SvgIcon name={startIcon.name} size={startIcon.size} />
            : null
        }
        ref={ref}
        $pill={pill}
        $border={border}
        variant={variant}
        color={color}
        light={light}
        href={href}
        {...rest}
      >
        {children}
        {loading && <Loading color={color} light={light} />}
      </StyledButton>
    </ThemeProvider>
  );
});

const small = css`
  height: 24px;
`;
const smallText = css`
  font-size: 12px;
`;
const medium = css`
  height: 32px;
`;
const large = css`
  height: 40px;
`;

const sizes = {
  small: small,
  medium: medium,
  large: large
};

const textSizes = {
  small: smallText,
  medium: undefined,
  large: undefined
};

const StyledButton = styled(MuiButton)`
  outline: none;
  font-weight: 500;
  font-size: 16px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  line-height: 1.5rem;
  height: auto !important;
  padding-top: ${({ size }) => size === 'large' ? '1rem' : '0.5rem'};
  padding-bottom: ${({ size }) => size === 'large' ? '1rem' : '0.5rem'};
  box-shadow: none;
  text-transform:  none;
  opacity: ${({ variant, disabled }) => variant === 'outlined' && disabled ? '0.8' : '1'};
  border-radius: 0.25rem;
  &:hover {
    color:  ${({ theme, color }) => theme.palette[color || 'primary'].main};
    opacity: 0.85;
  }

  &:focus {
    outline-color: transparent;
  }

  ${({ $pill }) => $pill && css`
    border-radius: 50rem;
  `}
  ${({ $border, theme }) => $border && css`
    border: 1px solid ${theme.palette.primary.main};
  `}
  ${({ size }) => sizes[size || 'medium']}
  ${({ $textSize }) => textSizes[$textSize || 'medium']}
  ${({ $noWrap }) => $noWrap && css`
    white-space: nowrap;
    min-width: auto;
  `}
  ${({ variant, color, $light }) => variant === 'contained' && css`
    &:hover {
      ${color === 'primary' && css`
        color:  ${({ theme }) => theme.palette.secondary.main};
        background-color: ${({ theme }) => theme.palette.primary['90']};
      `}
      ${color === 'secondary' && css`
        color:  ${({ theme }) => theme.palette.primary.main};
        background-color: ${({ theme }) => theme.palette.secondary.light};
      `}
      ${$light && css`
        color:  ${({ theme }) => theme.palette[color].light};
        background-color: ${({ theme }) => theme.palette[color].main};
      `}
      box-shadow: none;
    }
    ${$light && css`
      color:  ${({ theme }) => theme.palette[color].main};
      background-color: ${({ theme }) => theme.palette[color].light};
    `}
    ${({ disabled, theme }) => disabled && css`
      background-color: ${theme.palette.primary[30]} ;
      color: ${theme.palette.secondary.main} ;
      border: none ;
    `}
  `}
  ${({ variant, disabled, theme }) => (variant === 'outlined' && disabled === true) && `
    border-color: ${theme.palette.primary[30]} ;
    color: ${theme.palette.primary[30]} ;
  `}
`;

const Loading = styled(CircularProgress).attrs({
  size: 18,
  thickness: 4.6,
  sx: {
    ml: 0.5,
    color: 'secondary.main'
  }
})`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.palette.secondary.main};

  ${({ light, color }) => light && css`
    color: ${({ theme }) => theme.palette[color].main};

    button:hover > & {
      color: ${({ theme }) => theme.palette[color].light};
    }
  `}
`;

export default Button;
