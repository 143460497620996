import React from 'react';
import Swiper from './Swiper';
import { Box, Typography, Avatar, SvgIcon } from '../elements';
import { Badge } from '@mui/material';

import { FeedbackContainer, FeedbackQuoteDecoration } from './FeedbackSlider.styles';

const FeedbackSlider = ({ feedbacks }) => {
  return (
    <Swiper
      slides={feedbacks.map((feedback, index) => (
        <FeedbackContainer key={index}>
          <Box sx={{ display: 'flex', minHeight: 1, alignContent: 'center', pt: 1 }}>
            <FeedbackQuoteDecoration />
            <Typography
              variant="h5"
              component="blockquote"
              sx={{ typography: 'h5', fontWeight: 'normal !important' }}
            >
              {feedback.description}
            </Typography>
            <FeedbackQuoteDecoration $endQuote/>
          </Box>
          <Box sx={{ display: 'flex', mt: 2, pb: 3, px: 1 }}>
            <Badge
              badgeContent={(
                <SvgIcon name="quotation_marks" size={18} />
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              color="primary"
              overlap="circular"
              sx={{
                span: {
                  height: 36,
                  width: 36,
                  borderRadius: '50rem'
                }
              }}
            >
              <Avatar
                src={feedback.photo_url}
                sx={{ width: 70, height: 70 }}
              />
            </Badge>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              ml: 1,
              textAlign: 'left'
            }}>
              <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>
                {feedback.employee_name}
              </Typography>
              <Typography variant="subtitle2" component="span" sx={{ color: 'primary.light' }}>
                {feedback.job_title}
              </Typography>
            </Box>
          </Box>
        </FeedbackContainer>
      ))}
    />
  );
};

export default FeedbackSlider;
