import React from 'react';
import { Box, Button, Dialog, Divider, Drawer, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '../../elements';
import { t } from '../../../../js/common/translations';
import { useBreakpoint } from '../../../hooks/useBreakpoint';

const FilterSort = ({ handleOpenDialog, filterSortModalOpen, searchAttributes }) => {
  const isMobile = ['xs', 'sm'].includes(useBreakpoint());
  const [value, setValue] = React.useState(searchAttributes.ordering);
  const sortItems = [
    {
      label: t('search_page.toolbar.sort.availability'),
      radioValue: ''
    },
    {
      label: t('search_page.toolbar.sort.price_asc'),
      radioValue: 'price_asc'
    },
    {
      label: t('search_page.toolbar.sort.price_desc'),
      radioValue: 'price_desc'
    },
    {
      label: t('search_page.toolbar.sort.sqm_asc'),
      radioValue: 'sqm_asc'
    },
    {
      label: t('search_page.toolbar.sort.sqm_desc'),
      radioValue: 'sqm_desc'
    },
    {
      label: t('search_page.toolbar.sort.bedrooms_asc'),
      radioValue: 'bedrooms_asc'
    },
    {
      label: t('search_page.toolbar.sort.bedrooms_desc'),
      radioValue: 'bedrooms_desc'
    }
  ];

  const RadioItems = () => (
    <Box component="form">
      <Box display="none">
        {searchAttributes.neighbourhoods && searchAttributes.neighbourhoods.map(element => <input key={element} name='search[neighbourhoods][]' value={element} />)}
        {searchAttributes.check_in && <input name='search[check_in]' value={searchAttributes.check_in} />}
        {searchAttributes.check_out && <input name='search[check_out]' value={searchAttributes.check_out} />}
        {searchAttributes.internal_id && <input name='search[internal_id]' value={searchAttributes.internal_id} />}
        {searchAttributes.address && <input name='search[address]' value={searchAttributes.address} />}
        {searchAttributes.bedrooms && searchAttributes.bedrooms.map(element => <input key={element} name='search[bedrooms][]' value={element} />)}
        {searchAttributes.bathrooms && searchAttributes.bathrooms.map(element => <input key={element} name='search[bathrooms][]' value={element} />)}
        {searchAttributes.min_price && <input name='search[min_price]' value={searchAttributes.min_price} />}
        {searchAttributes.max_price && <input name='search[max_price]' value={searchAttributes.max_price} />}
        {searchAttributes.facilities && searchAttributes.facilities.map(element => <input key={element} name='search[facilities][]' value={element} />)}
        {searchAttributes.bedtypes.length !== 0 && searchAttributes.bedtypes.map(element => <input key={element} name='search[bedtypes][]' value={element} />)}
      </Box>
      <FormControl fullWidth>
        <RadioGroup
          name="search[ordering]"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {sortItems.map(({ label, radioValue }, i) => {
            const divisorIndex = [0, 2, 4];
            return (
              <Box key={i}>
                <FormControlLabel
                  sx={{
                    color: 'primary.70',
                    display: 'flex',
                    justifyContent: 'space-between',
                    ml: 0,
                    minWidth: { xs: 230, md: 400 },
                    '& .MuiFormControlLabel-label': {
                      ...(value === radioValue) && {
                        fontWeight: '600',
                        color: 'primary.main'
                      }
                    }
                  }}
                  labelPlacement="start"
                  control={<Radio />}
                  value={radioValue}
                  label={label}
                />
                {divisorIndex.includes(i) && <Divider sx={{ my: 0.5 }} />}
              </Box>
            );
          })}
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Button
              size="large"
              type="submit"
              id="modal-sort-submit"
            >
              {t('search_page.toolbar.sort.sort_modal_btn')}
            </Button>
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );

  return (
    isMobile ? (
      <Drawer
        anchor="bottom"
        onClose={() => handleOpenDialog('sort')}
        headerContent={(
          <Typography color="primary" variant="h5" component="span">
            {t('search_page.toolbar.sort.sort_modal_title')}
          </Typography>
        )}
        open={filterSortModalOpen}
      >
        <Box m={1}>
          <RadioItems />
        </Box>
      </Drawer>
    ) : (
      <Dialog
        id="modalSort"
        onClose={() => handleOpenDialog('sort')}
        open={filterSortModalOpen}
        headerContent={
          <Typography color="primary" variant="h6" component="span">{t('search_page.toolbar.sort.sort_modal_title')}</Typography>
        }
      >
        <RadioItems />
      </Dialog>
    )
  );
};

export default FilterSort;
