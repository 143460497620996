import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: factor => `${1 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400
    }
  },
  palette: {
    primary: {
      light: '#666B6E',
      main: '#292D32',
      dark: '#000000',
      100: {
        main: '#292D32',
        10: '#E9EAEA'
      },
      90: '#3C3F44',
      80: '#4F5256',
      70: '#616467',
      60: '#75777A',
      50: '#86898B',
      40: '#999B9D',
      30: '#ACAEAF',
      20: '#BFC0C1',
      10: '#D3D4D4',
      5: '#F1F1F1'
    },
    secondary: {
      light: '#F8F9FE',
      main: '#FEFEFE',
      dark: '#FFFFFF',
      70: '#FAFBFE',
      40: '#BEBBBB',
      35: '#E1DEDE',
      30: '#EFEFEF'
    },
    background: {
      default: '#FEFEFE',
      outlineImage: '#FDF9F880',
      100: '#FAFAFC',
      90: '#EDEDF0'
    },
    error: {
      light: '#FFDCE4',
      main: '#FF5252',
      dark: '#DB340B',
      70: '#FF7272'
    },
    success: {
      light: '#EBFAF0',
      main: '#35B068',
      dark: '#008537'
    },
    warning: {
      light: '#FFFAE6',
      main: '#FFDC66',
      dark: '#B56200',
      70: '#FFB31A'
    },
    info: {
      light: '#CFE2FC',
      main: '#005EB8'
    },
    divider: '#EAE9EB',
    border: '#F2F2F2'
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600
    },
    lead: {
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: 'Poppins'
    }
  }
});

export default theme;
