import styled from 'styled-components';
import { Typography } from '../elements';

export const DescriptionTitle = styled(Typography).attrs({
  component: 'h4',
  variant: 'h3'
})`
  font-size: 1.25rem;
  margin-top: ${({ first }) => first ? '0' : '1.25rem'};
  font-family: 'Cambon';
  font-weight: 900;
`;

export const DescriptionText = styled(Typography).attrs({
  component: 'p'
})`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-family: 'Poppins';
`;
