import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  SvgIcon,
  Typography,
  TextField
} from '../elements';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import inputValidator from '../../utils/inputValidator';

const ChangePassword = ({ url }) => {
  const formRef = useRef(null);
  const [errorsText, setErrorsText] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [passwordValues, setPasswordValues] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: ''
  });

  const formValidations = [
    ['password', { validation: 'password', errorMessage: t('form_errors.password_input') }],
    ['password', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['confirmPassword', { validation: 'required', errorMessage: t('form_errors.empty_field') }],
    ['password', { validation: () => passwordValues.currentPassword !== passwordValues.password, errorMessage: t('new_password_page.info_text') }],
    ['confirmPassword', { validation: () => passwordValues.password === passwordValues.confirmPassword, errorMessage: t('form_errors.confirm_password_message') }]
  ];

  const handleChange = (inputName) => ({ target: { value } }) => {
    setPasswordValues(state => ({
      ...state,
      [inputName]: value
    }));
  };

  useEffect(() => {
    const { password, confirmPassword, currentPassword } = passwordValues;
    if (password || confirmPassword || currentPassword) {
      setErrorsText(inputValidator(passwordValues, formValidations));
    }
  }, [passwordValues]);

  const handleSubmit = () => {
    const errors = inputValidator(passwordValues, formValidations);

    if (errors.length) {
      setErrorsText(errors);
    } else {
      formRef.current.submit();
    }
  };

  const getErrorMessage = (inputName) => {
    return errorsText.filter(el => el.field === inputName)[0]?.error;
  };

  return (
    <ThemeProvider>
      <Box
        action={url}
        component="form"
        id="edit_member"
        className="needs-validation"
        method="POST"
        novalidate
        ref={formRef}
      >
        <Container
          disableGutters
          maxWidth="xs"
          sx={{
            p: 1.5,
            bgcolor: 'secondary.main',
            py: [4, 2, 3],
            marginTop: 2,
            borderRadius: 2
          }}
        >
          <Typography
            align="center"
            component="h1"
            mb={1}
            variant="h3"
          >
            {t('new_password_page.title')}
          </Typography>
          <Typography
            align="center"
            mb={1}
          >
            {t('new_password_page.info_text')}
          </Typography>
          <TextField
            error={!!getErrorMessage('currentPassword')}
            fullWidth
            helperText={getErrorMessage('currentPassword')}
            type={showCurrentPassword ? 'text' : 'password'}
            label={t('new_password_page.current_password')}
            margin="dense"
            name="member[current_password]"
            onChange={handleChange('currentPassword')}
            placeholder={t('new_password_page.current_password')}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} disableRipple>
                  <SvgIcon
                    pl={0.875}
                    name={showCurrentPassword ? 'eye_slash_password' : 'eye_password'}
                  />
                </IconButton>
              )
            }}
          />
          <TextField
            error={!!getErrorMessage('password')}
            fullWidth
            helperText={getErrorMessage('password')}
            label={t('new_password_page.new_password')}
            type={showPassword ? 'text' : 'password'}
            margin="dense"
            name="member[password]"
            onChange={handleChange('password')}
            placeholder={t('new_password_page.new_password')}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} disableRipple>
                  <SvgIcon
                    pl={0.875}
                    name={showPassword ? 'eye_slash_password' : 'eye_password'}
                  />
                </IconButton>
              )
            }}
          />
          <TextField
            error={!!getErrorMessage('confirmPassword')}
            fullWidth
            helperText={getErrorMessage('confirmPassword')}
            label={t('new_password_page.confirm_password')}
            margin="dense"
            onChange={handleChange('confirmPassword')}
            placeholder={t('new_password_page.confirm_password')}
            type={showConfirm ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowConfirm(!showConfirm)} disableRipple>
                  <SvgIcon
                    pl={0.875}
                    name={showConfirm ? 'eye_slash_password' : 'eye_password'}
                  />
                </IconButton>
              )
            }}
          />
          <Button
            fullWidth
            sx={{ my: 1 }}
            size="large"
            name="commit"
            onClick={handleSubmit}
          >
            {t('new_password_page.confirm_password_submit')}
          </Button>
        </Container>
        <input type="hidden" name="_method" value="PATCH" />
      </Box>
    </ThemeProvider>
  );
};

export default ChangePassword;
