import styled, { css } from 'styled-components';
import { Toolbar as MuiToolbar } from '@mui/material';

const Toolbar = styled(MuiToolbar)`

  ${({ variant }) => !variant && css`
    && {
      min-height: 56px;
    }
  `}
  && {
    padding-left: 16px;
    padding-right: 16px;
  }

  flex-wrap: nowrap;

`;

export default Toolbar;
